import { useEffect, useState } from "react"
import {
  EditEgresadoModalProps,
  IEgresados,
} from "../../../interfaces/IEgresados"
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react"
import { IEventos } from "../../../interfaces/IEventos"
import { CircularProgress } from "@mui/material"
import SpinnerOverlay from "../../SpinnerOverlay/SpinnerOverlay"
import { generatePassword } from "../../../utils/claveAndEncryptUtils"
import {
  formatDateGuiones,
  hasEventPassed,
  sortEventosByDate,
} from "../../../utils/dateUtils"

const EditEgresadoModal: React.FC<EditEgresadoModalProps> = ({
  show,
  handleClose,
  handleEgresadoEdit,
  selectedEgresado,
  idEvento,
}) => {
  const [nombre, setNombre] = useState("")
  const [apellido, setApellido] = useState("")
  const [dni, setDni] = useState("")
  const [clave, setClave] = useState("")
  const [tarjetasPagadas, setTarjetasPagadas] = useState("")
  const [tarjetasIngresadas, setTarjetasIngresadas] = useState("")
  const [tarjetasPorIngresar, setTarjetasPorIngresar] = useState("")
  const [tarjetasPagadasMenores, setTarjetasPagadasMenores] = useState("")
  const [tarjetasIngresadasMenores, setTarjetasIngresadasMenores] = useState("")
  const [tarjetasPorIngresarMenores, setTarjetasPorIngresarMenores] =
    useState("")
  const [email, setEmail] = useState("")
  const [telefono, setTelefono] = useState("")
  const [menuOpcional, setMenuOpcional] = useState("")
  const [observaciones, setObservaciones] = useState("")
  const [eventoId, setIdEventoId] = useState(idEvento)
  const [eventoCompletoPorId, setEventoCompletoPorId] = useState<IEventos>()
  const [eventos, setEventos] = useState<IEventos[]>([])
  const [dniValid, setDniValid] = useState(true)
  const [dniEnUso, setDniEnUso] = useState(false)
  const [originalDni, setOriginalDni] = useState("")
  const { getAccessTokenSilently } = useAuth0()
  const API_URL = process.env.REACT_APP_API_SERVER_URL_EGRE || ""
  const API_URL_EVENTOS = process.env.REACT_APP_API_SERVER_URL_EVENTOS || ""
  const [mensaje, setMensaje] = useState(false)
  const [emailValido, setEmailValido] = useState(true)
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
  const [tarjetasValidacionError, setTarjetasValidacionError] = useState(false)
  const [tarjetasMenoresValidacionError, setTarjetasMenoresValidacionError] =
    useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingDni, setLoadingDni] = useState(false)

  useEffect(() => {
    const callApiAndSetEventos = async () => {
      setLoading(true)
      try {
        const token = await getAccessTokenSilently()
        const response = await axios.get(`${API_URL_EVENTOS}/${eventoId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        setEventoCompletoPorId(response.data)
      } catch (error) {
        console.error("Error al obtener eventos:", error)
      } finally {
        setLoading(false)
      }
    }
    callApiAndSetEventos()
  }, [eventoId])

  useEffect(() => {
    if (selectedEgresado) {
      setNombre(selectedEgresado.nombre)
      setApellido(selectedEgresado.apellido)
      setDni(selectedEgresado.dni.toString())
      setClave(selectedEgresado.clave)
      setTarjetasPagadas(selectedEgresado.tarjetasPagadas.toString())
      setTarjetasIngresadas(
        selectedEgresado.tarjetasIngresadas?.toString() || "0"
      )
      setTarjetasPorIngresar(
        selectedEgresado.tarjetasPorIngresar?.toString() || "0"
      )
      setTarjetasPagadasMenores(
        selectedEgresado.tarjetasPagadasMenores.toString()
      )
      setTarjetasIngresadasMenores(
        selectedEgresado.tarjetasIngresadasMenores?.toString() || "0"
      )
      setTarjetasPorIngresarMenores(
        selectedEgresado.tarjetasPorIngresarMenores?.toString() || "0"
      )
      setEmail(selectedEgresado.email)
      setTelefono(selectedEgresado.telefono)
      setMenuOpcional(selectedEgresado.menuOpcional)
      setObservaciones(selectedEgresado.observaciones)
      const initialEventoId = idEvento || selectedEgresado.evento?.id || ""
      setIdEventoId(initialEventoId.toString())

      setOriginalDni(selectedEgresado.dni.toString())

      // Validar DNI al cargar
      if (isDNIValid(selectedEgresado.dni.toString())) {
        setDniValid(true)
      } else {
        setDniValid(false)
      }

      setDniEnUso(false)
    }

    const callApiAndSetEventos = async () => {
      setLoading(true)
      try {
        const token = await getAccessTokenSilently()
        const response = await axios.get(API_URL_EVENTOS, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        setEventos(sortEventosByDate(response.data))
      } catch (error) {
        console.error("Error al obtener eventos:", error)
      } finally {
        setLoading(false)
      }
    }

    callApiAndSetEventos()
  }, [selectedEgresado, getAccessTokenSilently, API_URL_EVENTOS])

  const isDNIValid = (dni: string) => {
    const dniPattern = /^\d{8}$/ // Verifica que el DNI tenga exactamente 8 dígitos
    return dniPattern.test(dni)
  }

  const handleClaveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.includes("-")) {
      setMensaje(true)
      return
    }
    setClave(event.target.value)
  }

  const validateDNI = async (dni: string) => {
    setLoadingDni(true)
    try {
      const token = await getAccessTokenSilently()
      const response = await axios.post(
        `${API_URL}/validarDNI`,
        [parseInt(dni)],
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      const dniExistentes = response.data.dniExistentes
      return dniExistentes.length > 0
    } catch (error) {
      console.error("Error al verificar el DNI:", error)
      return false
    } finally {
      setLoadingDni(false)
    }
  }

  const handleApellidoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newApellido = event.target.value
    setApellido(newApellido)
    if (newApellido && dni.length === 8) {
      setClave(generatePassword(newApellido, dni))
    } else {
      setClave("")
    }
  }

  const handleDniChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newDni = event.target.value
    setDni(newDni)

    // Validar que el DNI tenga 8 caracteres
    if (newDni.length === 8) {
      setDniValid(true)
      const isValid = await validateDNI(newDni)
      setDniEnUso(isValid) // Actualiza si el DNI está en uso
    } else {
      setDniValid(false) // Setear a inválido si no tiene 8 dígitos
      setDniEnUso(false) // Resetea el estado de "DNI en uso"
    }

    if (apellido && newDni.length === 8) {
      setClave(generatePassword(apellido, newDni))
    } else {
      setClave("")
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!isDNIValid(dni)) {
      setDniValid(false)
      return
    }

    if (dni !== originalDni) {
      if (await validateDNI(dni)) {
        setDniEnUso(true)
        return
      }
    }

    const tarjetasPagadasNumber = parseInt(tarjetasPagadas)

    if (!!email && !emailRegex.test(email)) {
      setEmailValido(false)
      return
    } else {
      setEmailValido(true)
    }

    const tarjetasIngresadasNumber = parseInt(tarjetasIngresadas || "0")
    const tarjetasPorIngresarNumber = parseInt(tarjetasPorIngresar || "0")

    if (
      tarjetasPagadasNumber !==
      tarjetasIngresadasNumber + tarjetasPorIngresarNumber
    ) {
      setTarjetasValidacionError(true)
      return
    } else {
      setTarjetasValidacionError(false)
    }

    //validaciones para tarjetas de Menores
    const tarjetasPagadasMenoresNumber = parseInt(tarjetasPagadasMenores || "0")
    const tarjetasIngresadasMenoresNumber = parseInt(
      tarjetasIngresadasMenores || "0"
    )
    const tarjetasPorIngresarMenoresNumber = parseInt(
      tarjetasPorIngresarMenores || "0"
    )

    if (
      tarjetasPagadasMenoresNumber !==
      tarjetasIngresadasMenoresNumber + tarjetasPorIngresarMenoresNumber
    ) {
      setTarjetasMenoresValidacionError(true)
      return
    } else {
      setTarjetasMenoresValidacionError(false)
    }

    if (selectedEgresado) {
      const updatedEgresado: IEgresados = {
        ...selectedEgresado,
        nombre,
        apellido,
        dni: parseInt(dni),
        clave,
        tarjetasPagadas: parseInt(tarjetasPagadas),
        tarjetasIngresadas: parseInt(tarjetasIngresadas),
        tarjetasPorIngresar: parseInt(tarjetasPorIngresar),
        tarjetasPagadasMenores: parseInt(tarjetasPagadasMenores),
        tarjetasIngresadasMenores: parseInt(tarjetasIngresadasMenores),
        tarjetasPorIngresarMenores: parseInt(tarjetasPorIngresarMenores),
        telefono,
        email,
        menuOpcional,
        observaciones,
        eventoId: eventoId ? parseInt(eventoId) : null,
      }
      handleEgresadoEdit(updatedEgresado)
    }
    setMensaje(false)
    handleClose()
  }

  const resetForm = () => {
    if (selectedEgresado) {
      setNombre(selectedEgresado.nombre)
      setApellido(selectedEgresado.apellido)
      setDni(selectedEgresado.dni.toString())
      setClave(selectedEgresado.clave)
      setTarjetasPagadas(selectedEgresado.tarjetasPagadas.toString())
      setTarjetasIngresadas(
        selectedEgresado.tarjetasIngresadas?.toString() || "0"
      )
      setTarjetasPorIngresar(
        selectedEgresado.tarjetasPorIngresar?.toString() || "0"
      )
      setIdEventoId(
        selectedEgresado.eventoId ? selectedEgresado.eventoId.toString() : ""
      )
    }
    setDniValid(true)
    setDniEnUso(false)
    setMensaje(false)
    handleClose()
  }

  const handleCancelar = () => {
    resetForm()
    handleClose()
  }

  useEffect(() => {
    if (!!email) {
      if (!emailRegex.test(email)) {
        setEmailValido(false)
        return
      } else {
        setEmailValido(true)
      }
    }
  }, [email])

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Editar Egresado</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          {loading && <SpinnerOverlay />}
          <Row>
            <Col md={6}>
              <Form.Group controlId="formNombre">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese nombre"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formApellido">
                <Form.Label>Apellido</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese apellido"
                  value={apellido}
                  onChange={handleApellidoChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formDni">
                <Form.Label>DNI</Form.Label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese DNI"
                    value={dni}
                    onChange={handleDniChange}
                    required
                    isInvalid={!dniValid || dniEnUso}
                  />
                  {loadingDni && (
                    <div
                      style={{
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <CircularProgress size={24} />
                    </div>
                  )}
                </div>
                {!dniValid && (
                  <Form.Text className="text-danger">
                    El DNI debe tener exactamente 8 caracteres.
                  </Form.Text>
                )}
                {dniEnUso && (
                  <Form.Text className="text-danger">
                    Este DNI ya está en uso.
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formTelefono">
                <Form.Label>Teléfono</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese teléfono"
                  value={telefono}
                  onChange={(e) => setTelefono(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Ingrese email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  isInvalid={email != "" && !emailValido ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                  Email no válido.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formClave">
                <Form.Label>Clave</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese clave"
                  value={clave}
                  onChange={handleClaveChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  La clave no debe contener el carácter '-'
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group controlId="formTarjetasPagadas">
                <Form.Label>Tarjetas Pagadas</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Ingrese tarjetas pagadas"
                  value={tarjetasPagadas}
                  onChange={(e) => setTarjetasPagadas(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formTarjetasIngresadas">
                <Form.Label>Tarjetas Ingresadas</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Ingrese tarjetas ingresadas"
                  value={tarjetasIngresadas}
                  onChange={(e) => setTarjetasIngresadas(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formTarjetasPorIngresar">
                <Form.Label>Tarjetas por Ingresar</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Ingrese tarjetas por ingresar"
                  value={tarjetasPorIngresar}
                  onChange={(e) => setTarjetasPorIngresar(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group controlId="formTarjetasPagadasMenores">
                <Form.Label>Tarjetas Pagadas Menores</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Ingrese tarjetas pagadas Menores"
                  value={tarjetasPagadasMenores}
                  onChange={(e) => setTarjetasPagadasMenores(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formTarjetasIngresadasMenores">
                <Form.Label>Tarjetas Ingresadas Menores</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Ingrese tarjetas ingresadas Menores"
                  value={tarjetasIngresadasMenores}
                  onChange={(e) => setTarjetasIngresadasMenores(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formTarjetasPorIngresarMenores">
                <Form.Label>Tarjetas por Ingresar Menores</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Ingrese tarjetas por ingresar Menores"
                  value={tarjetasPorIngresarMenores}
                  onChange={(e) =>
                    setTarjetasPorIngresarMenores(e.target.value)
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Form.Group className="mb-3" controlId="formMenuOpcional">
                <Form.Label>Menú Opcional</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Ingrese Menú Opcional"
                  value={menuOpcional}
                  onChange={(event) => setMenuOpcional(event.target.value)}
                  style={{ resize: "none" }}
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="mb-3" controlId="formObservaciones">
                <Form.Label>Observaciones</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Ingrese Observaciones"
                  value={observaciones}
                  onChange={(event) => setObservaciones(event.target.value)}
                  style={{ resize: "none" }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group controlId="formEvento">
                <Form.Label>Evento</Form.Label>
                <Form.Control
                  as="select"
                  value={eventoId}
                  onChange={(event) => setIdEventoId(event.target.value)}
                  required
                >
                  <option value={eventoCompletoPorId?.id}>
                    {`${eventoCompletoPorId?.nombre} / ${formatDateGuiones(
                      eventoCompletoPorId?.fecha || ""
                    )}`}
                  </option>
                  {eventos.map((evento: IEventos) => {
                    const isEventPassed = hasEventPassed(evento.fecha)
                    return (
                      <option
                        key={evento.id}
                        value={evento.id}
                        style={{ color: isEventPassed ? "#a6a8aa" : "black" }}
                      >
                        {`${evento.nombre} / ${formatDateGuiones(
                          evento.fecha
                        )}`}
                      </option>
                    )
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          {tarjetasValidacionError && (
            <div className="alert alert-danger" role="alert">
              La suma de tarjetas ingresadas y por ingresar debe ser igual a las
              tarjetas pagadas.
            </div>
          )}
          {tarjetasMenoresValidacionError && (
            <div className="alert alert-danger" role="alert">
              La suma de tarjetas ingresadas de Menores y por ingresar de
              Menores debe ser igual a las tarjetas pagadas de Menores.
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelar}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">
            Guardar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default EditEgresadoModal
