import React, { FC, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import BlockedModal from '../BlockedModal/BlockedModal';

/**
 * Botón para iniciar sesión utilizando Auth0.
 */
const LoginButton: FC = () => {
  const { loginWithRedirect, user } = useAuth0();
  const [showBlockedModal, setShowBlockedModal] = useState(false);

  // Verificar si el usuario está bloqueado
  const isBlocked = user?.blocked;

  const handleLogin = () => {
    if (isBlocked) {
      setShowBlockedModal(true);
    } else {
      loginWithRedirect({
        appState: {
          returnTo: window.location.pathname
        }
      });
    }
  };

  return (
    <>
      {isBlocked && (
        <BlockedModal
          show={showBlockedModal}
          onClose={() => setShowBlockedModal(false)}
        />
      )}
      <button
        className="btn btn-primary"
        onClick={handleLogin}
      >
        Administración
      </button>
    </>
  );
};

export default LoginButton;