import React, { useRef } from "react"
import { Button } from "react-bootstrap"

interface FileUploadButtonProps {
  onFileChange: (file: File) => void // Cambia el tipo para recibir solo el archivo
  onError: (message: string) => void // Función para manejar errores
}

const FileUploadButton: React.FC<FileUploadButtonProps> = ({
  onFileChange,
  onError,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const handleButtonClick = () => {
    fileInputRef.current?.click()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const validTypes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ]

      if (validTypes.includes(file.type)) {
        onFileChange(file) // Pasa el archivo si es válido
      } else {
        onError("Tipo de archivo no válido. Por favor, sube un archivo Excel.")
      }
    }
  }

  return (
    <div>
      <input
        type="file"
        accept=".xlsx, .xls"
        ref={fileInputRef}
        onChange={handleChange}
        style={{ display: "none" }} // Ocultar el input de archivo
      />
      <Button variant="primary" onClick={handleButtonClick}>
        Subir archivo
      </Button>
    </div>
  )
}

export default FileUploadButton
