import React from "react";

const Bienvenida = () => {
    const estiloFondo = {
        backgroundImage: `url('/assets/img/FondoBienvenida.jpg')`,
        backgroundSize: "cover",
        minHeight: "91vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
    };

    return (
        <div style={estiloFondo}>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 offset-md-3 text-center">
                        <h1 className="display-4 mb-4">¡Bienvenidos a la Fiesta de Egresados!</h1>
                        <p className="lead fs-4">
                            ¡Es un placer darles la bienvenida a esta emocionante celebración en honor a todos nuestros egresados! Prepárense para una noche llena de diversión, música y recuerdos inolvidables.
                        </p>
                        <p className="fs-5">
                            ¡No se pierdan esta oportunidad de reencontrarse con viejos amigos y celebrar juntos sus logros académicos!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Bienvenida;
