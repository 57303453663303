import { FC } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

interface BlockedModalProps {
    show: boolean;
    onClose: () => void;
}

const BlockedModal: FC<BlockedModalProps> = ({ show, onClose }) => {
    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Usuario Bloqueado</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>No puedes iniciar sesión porque tu cuenta está bloqueada.</p>
            </Modal.Body>
            <Modal.Footer>
                <Link to={"/"}>
                    <Button variant="danger" onClick={onClose}>
                        Cerrar
                    </Button>
                </Link>
            </Modal.Footer>
        </Modal>
    );
};

export default BlockedModal;
