import React from "react"
import { Box, Container, Typography } from "@mui/material"

const SobreNosotros = () => {
  return (
    <Box
      sx={{
        height: "auto",
        backgroundImage: `url('assets/img/ComoFunc.jpg')`,
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        padding: 4,
      }}
    >
      <Container>
        <Box textAlign="center" mb={4} mt={8}>
          <Typography variant="h4" component="h2" color="black" gutterBottom>
            Salón Da Vinci Festa
          </Typography>
          <Box
            component="video"
            controls
            sx={{
              width: {
                xs: "20rem", // Estilo para móviles
                md: "45rem", // Estilo para pantallas medianas y más grandes
              },
              height: {
                xs: "18rem", // Modifica la altura para móviles
                md: "28rem", // Altura para pantallas medianas y más grandes
              },
              margin: "0 auto",
            }}
          >
            <source src="assets/img/VideoDaVinciFesta.mp4" type="video/mp4" />
            Tu navegador no soporta el tag de video.
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default SobreNosotros
