import jsQR from "jsqr";

export function scanQRCode(videoElement, callback) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d", { willReadFrequently: true });
  context.filter = "contrast(200%) brightness(150%)";

  function scan() {
    if (videoElement.readyState === videoElement.HAVE_ENOUGH_DATA) {
      const scanRegionWidth = Math.floor(videoElement.videoWidth / 2);
      const scanRegionHeight = Math.floor(videoElement.videoHeight / 2);
      canvas.width = scanRegionWidth;
      canvas.height = scanRegionHeight;
      context.drawImage(videoElement, 0, 0, scanRegionWidth, scanRegionHeight);
      context.filter = "none";
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

      const code = jsQR(imageData.data, imageData.width, imageData.height);

      if (code) {
        callback(code.data);
        return; // Detener la animación si se encuentra un código QR
      }
    }
    requestAnimationFrame(scan); // Continuar escaneando
  }

  requestAnimationFrame(scan); // Iniciar el escaneo
}
