import React, { useEffect, useState } from "react"
import { AddEgresadoModalProps, IEgresados } from "../../interfaces/IEgresados"
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react"
import { CircularProgress } from "@mui/material"
import { generatePassword } from "../../utils/claveAndEncryptUtils"

const AddEgresadoModal: React.FC<AddEgresadoModalProps> = ({
  show,
  handleClose,
  handleEgresadoAdd,
  idEvento,
}) => {
  const [nombre, setNombre] = useState("")
  const [apellido, setApellido] = useState("")
  const [dni, setDni] = useState("")
  const [clave, setClave] = useState("")
  const [tarjetasPagadas, setTarjetasPagadas] = useState("0")
  const [tarjetasPagadasMenores, setTarjetasPagadasMenores] = useState("0")
  const [email, setEmail] = useState("")
  const [telefono, setTelefono] = useState("")
  const [menuOpcional, setMenuOpcional] = useState("")
  const [observaciones, setObservaciones] = useState("")
  const [eventoId, setEventoId] = useState(idEvento)
  const [dniValid, setDniValid] = useState(true)
  const [dniEnUso, setDniEnUso] = useState(false)
  const API_URL = process.env.REACT_APP_API_SERVER_URL_EGRE || ""
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { getAccessTokenSilently } = useAuth0()
  const [mensaje, setMensaje] = useState(false)
  const [emailValido, setEmailValido] = useState(true)
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
  const [loadingDni, setLoadingDni] = useState(false)

  const resetForm = () => {
    setNombre("")
    setApellido("")
    setDni("")
    setClave("")
    setTarjetasPagadas("0")
    setTarjetasPagadasMenores("0")
    setEmail("")
    setMenuOpcional("")
    setObservaciones("")
    setTelefono("")
    setEventoId(idEvento)
    setDniEnUso(false)
    setMensaje(false)
    setIsSubmitting(false)
  }

  const handleClaveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.includes("-")) {
      setMensaje(true)
    } else {
      setMensaje(false)
      setClave(event.target.value)
    }
  }

  const handleApellidoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newApellido = event.target.value
    setApellido(newApellido)
    if (newApellido && dni.length === 8) {
      setClave(generatePassword(newApellido, dni))
    } else {
      setClave("")
    }
  }

  const validateDNI = async (dni: string) => {
    setLoadingDni(true)
    try {
      const token = await getAccessTokenSilently()
      const response = await axios.post(
        `${API_URL}/validarDNI`,
        [parseInt(dni)],
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      const dniExistentes = response.data.dniExistentes
      return dniExistentes.length > 0 // Devuelve true si el DNI ya existe
    } catch (error) {
      console.error("Error al verificar el DNI:", error)
      return false // Si hay un error, consideramos que el DNI es válido
    } finally {
      setLoadingDni(false)
    }
  }

  const handleDniChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newDni = event.target.value
    setDni(newDni)

    // Validar que el DNI tenga 8 caracteres
    if (newDni.length === 8) {
      setDniValid(true)
      const isValid = await validateDNI(newDni)
      setDniEnUso(isValid) // Actualiza si el DNI está en uso
    } else {
      setDniValid(false) // Setear a inválido si no tiene 8 dígitos
      setDniEnUso(false) // Resetea el estado de "DNI en uso"
    }

    if (apellido && newDni.length === 8) {
      setClave(generatePassword(apellido, newDni))
    } else {
      setClave("")
    }
  }

  useEffect(() => {
    if (!!email) {
      if (!emailRegex.test(email)) {
        setEmailValido(false)
        return
      } else {
        setEmailValido(true)
      }
    }
  }, [email])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!dniValid || dniEnUso) {
      return // Si el DNI no es válido o ya está en uso, no continuar
    }

    if (!!email && !emailRegex.test(email)) {
      setEmailValido(false)
      return
    } else {
      setEmailValido(true)
    }

    setIsSubmitting(true)

    const newEgresado: IEgresados = {
      id: 0,
      nombre,
      apellido,
      dni: parseInt(dni),
      clave,
      tarjetasPagadas: parseInt(tarjetasPagadas),
      tarjetasPagadasMenores: parseInt(tarjetasPagadasMenores),
      email,
      telefono,
      menuOpcional,
      observaciones,
      eventoId: parseInt(eventoId),
    }
    handleEgresadoAdd(newEgresado)
    handleClose()
    resetForm()
  }

  const handleCancelar = () => {
    resetForm()
    setTarjetasPagadas("0")
    setDniEnUso(false)
    setMensaje(false)
    handleClose()
  }

  const handleCloseModal = () => {
    resetForm()
    handleClose()
  }

  return (
    <Modal show={show} onHide={handleCloseModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Agregar Egresado</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Col md="6">
              <Form.Group className="mb-3" controlId="formNombre">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese nombre"
                  value={nombre}
                  onChange={(event) => {
                    setNombre(event.target.value)
                  }}
                  required
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="mb-3" controlId="formApellido">
                <Form.Label>Apellido</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese apellido"
                  value={apellido}
                  onChange={handleApellidoChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Form.Group className="mb-3" controlId="formDni">
                <Form.Label>DNI</Form.Label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Form.Control
                    type="number"
                    placeholder="Ingrese DNI"
                    value={dni}
                    onChange={handleDniChange}
                    required
                    isInvalid={!dniValid || dniEnUso}
                  />
                  {loadingDni && (
                    <div
                      style={{
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <CircularProgress size={24} />
                    </div>
                  )}
                </div>
                {/* Mensajes de validación */}
                {!dniValid && (
                  <Form.Text className="text-danger">
                    El DNI debe tener exactamente 8 caracteres.
                  </Form.Text>
                )}
                {dniEnUso && (
                  <Form.Text className="text-danger">
                    Este DNI ya está en uso.
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="mb-3" controlId="formClave">
                <Form.Label>Contraseña</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese contraseña"
                  value={clave}
                  onChange={handleClaveChange}
                  required
                  isInvalid={mensaje}
                />
                {mensaje && (
                  <Form.Text className="text-danger">
                    {"La clave no debe contener el carácter '-'"}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Form.Group className="mb-3" controlId="formCantTarjetas">
                <Form.Label>Tarjetas Pagadas</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Ingrese cantidad de Tarjetas"
                  value={tarjetasPagadas}
                  onChange={(event) => setTarjetasPagadas(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="mb-3" controlId="formCantTarjetasMenores">
                <Form.Label>Tarjetas Pagadas Menores</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Ingrese cantidad de Tarjetas"
                  value={tarjetasPagadasMenores}
                  onChange={(event) =>
                    setTarjetasPagadasMenores(event.target.value)
                  }
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Form.Group className="mb-3" controlId="formTelefono">
                <Form.Label>Teléfono</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese teléfono"
                  value={telefono}
                  onChange={(event) => setTelefono(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Ingrese email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  isInvalid={email != "" && !emailValido ? true : false}
                />
                {
                  <Form.Control.Feedback type="invalid">
                    Email no válido.
                  </Form.Control.Feedback>
                }
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Form.Group className="mb-3" controlId="formMenuOpcional">
                <Form.Label>Menú Opcional</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Ingrese Menú Opcional"
                  value={menuOpcional}
                  onChange={(event) => setMenuOpcional(event.target.value)}
                  style={{ resize: "none" }}
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="mb-3" controlId="formObservaciones">
                <Form.Label>Observaciones</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Ingrese Observaciones"
                  value={observaciones}
                  onChange={(event) => setObservaciones(event.target.value)}
                  style={{ resize: "none" }}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelar}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Agregar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddEgresadoModal
