import React, { useState, useEffect } from "react"
import { Modal, Button, Form, Row, Col } from "react-bootstrap"
import { IEmpleados, AddEmpleadoModalProps } from "../../interfaces/IEmpleados"
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react"
import { Auth0User } from "../../interfaces/Auth0"
import { useCreateUserAuth0 } from "../Auth0/Hooks/useCreateUserAuth0"
import { useAssignRoleToUserAuth0 } from "../Auth0/Hooks/useAssignRoleToUserAuth0"

const AddEmpleadoModal: React.FC<AddEmpleadoModalProps> = ({
  show,
  handleClose,
  handleEmpleadoAdd,
}) => {
  const [nombre, setNombre] = useState("")
  const [apellido, setApellido] = useState("")
  const [email, setEmail] = useState("")
  const [clave, setClave] = useState("")
  const [estado, setEstado] = useState(false)
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
  const [emailValido, setEmailValido] = useState(true)
  const [emailEnUso, setEmailEnUso] = useState(false)
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
  const API_URL = process.env.REACT_APP_API_SERVER_URL_EMPL || ""
  const { getAccessTokenSilently } = useAuth0()
  const ID_ROL = process.env.REACT_APP_ID_ROL_EMPL || ""
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    specialChar: false,
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [claveValida, setClaveValida] = useState(true)

  //Custom Hooks de Auth0
  const createUserAuth0 = useCreateUserAuth0()
  const assignRoleToUserAuth0 = useAssignRoleToUserAuth0()

  // Función para verificar la contraseña en tiempo real
  const checkPassword = (password: string) => {
    setPasswordValidation({
      length: password.length >= 8,
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[-_@$!%*?&]/.test(password),
    })
  }

  useEffect(() => {
    checkPassword(clave)
  }, [clave])

  // Esta función restablecerá los valores del formulario a su estado inicial
  const resetForm = () => {
    setNombre("")
    setApellido("")
    setEmail("")
    setClave("")
    setEstado(false)
    setEmailEnUso(false)
    setIsSubmitting(false)
    setClaveValida(true)
  }

  // Manejar el envío del formulario
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    // Verificar si el email ya está en uso
    try {
      // Obtener el token JWT
      const token = await getAccessTokenSilently()
      // Realizar la solicitud GET con el token en el encabezado
      const response = await axios.get(API_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const empleados = response.data

      // Verificar si el email ya está en uso
      const emailExists = empleados.some(
        (empleado: any) => empleado.email === email
      )

      if (emailExists) {
        setEmailEnUso(true)
        return
      } else {
        setEmailEnUso(false)
      }
    } catch (error) {
      console.error("Error al verificar el email:", error)
      return
    }

    // Verificar dirección de correo electrónico
    if (!emailRegex.test(email)) {
      setEmailValido(false)
      return
    } else {
      setEmailValido(true)
    }

    // Verificar las condiciones de la contraseña
    const isPasswordValid =
      passwordValidation.length &&
      passwordValidation.lowercase &&
      passwordValidation.uppercase &&
      passwordValidation.number &&
      passwordValidation.specialChar

    if (!isPasswordValid) {
      // Si la contraseña no cumple con las condiciones, puedes mostrar un mensaje o realizar alguna acción
      setClaveValida(false)
      console.error("La contraseña no cumple con los requisitos.")
      return
    }

    setIsSubmitting(true) //Si ya se apreto una vez el boton de agregar este se deshabilita

    const customerUser: Auth0User = {
      username: nombre,
      email: email,
      password: clave,
      blocked: estado,
    }
    try {
      // Crear el usuario en Auth0 y esperar a que se complete
      const newAuth0UserId = await createUserAuth0(customerUser)

      // Llama a la función para asignar idAuth0 al empleado
      const empleadoConAuth0Id = asignarAuth0Id(
        {
          id: 0,
          nombre,
          apellido,
          email,
          clave,
          estado,
          idAuth0: "", // Puedes inicializarlo en blanco y asignarlo luego
        },
        newAuth0UserId
      )
      // Asignar el rol al usuario en Auth0
      const roleId = ID_ROL
      await assignRoleToUserAuth0(newAuth0UserId, roleId)

      // Agrega el empleado con idAuth0 asignado
      handleEmpleadoAdd(empleadoConAuth0Id)

      handleClose()
      resetForm()
    } catch (error) {
      console.error("Error al crear el usuario en Auth0:", error)
      // Manejar errores adicionales si es necesario
    }
  }

  function asignarAuth0Id(
    empleado: IEmpleados,
    newAuth0ID: string
  ): IEmpleados {
    return {
      ...empleado,
      idAuth0: newAuth0ID,
    }
  }

  const handleCancelar = () => {
    resetForm()
    handleClose()
  }

  const handleCloseModal = () => {
    resetForm()
    handleClose()
  }

  return (
    <Modal show={show} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar Empleado</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Col md="6">
              <Form.Group className="mb-3" controlId="formNombre">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese nombre"
                  value={nombre}
                  onChange={(event) => setNombre(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="mb-3" controlId="formApellido">
                <Form.Label>Apellido</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese apellido"
                  value={apellido}
                  onChange={(event) => setApellido(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  required
                  isInvalid={!emailValido || emailEnUso}
                />
                {!emailValido && (
                  <Form.Control.Feedback type="invalid">
                    Email no válido.
                  </Form.Control.Feedback>
                )}
                {emailEnUso && (
                  <Form.Control.Feedback type="invalid">
                    Este email ya está en uso.
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="mb-3" controlId="formEstado">
                <Form.Label>Estado</Form.Label>
                <Form.Select
                  value={estado ? "activo" : "bloqueado"}
                  onChange={(event) =>
                    setEstado(event.target.value === "bloqueado" ? false : true)
                  }
                  required
                >
                  <option value="bloqueado">Activo</option>
                  <option value="activo">Bloqueado</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="5">
              <Form.Group className="mb-3" controlId="formClave">
                <Form.Label>Contraseña</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Contraseña"
                  value={clave}
                  onChange={(event) => setClave(event.target.value)}
                  required
                  isInvalid={!claveValida}
                />
                {!claveValida && (
                  <Form.Control.Feedback type="invalid">
                    Contraseña no válida.
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md="7">
              {clave && (
                <div className="card border-info shadow">
                  <ul className="list-unstyled">
                    <li
                      className={`password-validation-item ${
                        passwordValidation.length
                          ? "text-success"
                          : "text-danger"
                      }`}
                    >
                      {passwordValidation.length ? (
                        <i className="bi bi-check"></i>
                      ) : (
                        <i className="bi bi-x"></i>
                      )}
                      Al menos 8 caracteres de largo
                    </li>
                    <li
                      className={`password-validation-item ${
                        passwordValidation.lowercase
                          ? "text-success"
                          : "text-danger"
                      }`}
                    >
                      {passwordValidation.lowercase ? (
                        <i className="bi bi-check"></i>
                      ) : (
                        <i className="bi bi-x"></i>
                      )}
                      Letras minúsculas (a-z)
                    </li>
                    <li
                      className={`password-validation-item ${
                        passwordValidation.uppercase
                          ? "text-success"
                          : "text-danger"
                      }`}
                    >
                      {passwordValidation.uppercase ? (
                        <i className="bi bi-check"></i>
                      ) : (
                        <i className="bi bi-x"></i>
                      )}
                      Letras mayúsculas (A-Z)
                    </li>
                    <li
                      className={`password-validation-item ${
                        passwordValidation.number
                          ? "text-success"
                          : "text-danger"
                      }`}
                    >
                      {passwordValidation.number ? (
                        <i className="bi bi-check"></i>
                      ) : (
                        <i className="bi bi-x"></i>
                      )}
                      Números (0-9)
                    </li>
                    <li
                      className={`password-validation-item ${
                        passwordValidation.specialChar
                          ? "text-success"
                          : "text-danger"
                      }`}
                    >
                      {passwordValidation.specialChar ? (
                        <i className="bi bi-check"></i>
                      ) : (
                        <i className="bi bi-x"></i>
                      )}
                      Caracteres especiales <br /> (por ejemplo: -_@$!%*?&)
                    </li>
                  </ul>
                </div>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelar}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Agregar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddEmpleadoModal
