import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";

export const useDeleteUserAuth0 = () => {

    const { getAccessTokenSilently } = useAuth0();
    const url: string = process.env.REACT_APP_API_SERVER_URL || "";

    const deleteUserAuth0 = async (userId: string) => {
        try {
            const token = await getAccessTokenSilently();
            const requestBody = { userId };
            const response = await fetch(`${url}auth0/users/${userId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(requestBody),
            });
            if (!response.ok) {
                toast.error('Ha ocurrido un error', {
                    position: 'top-center',
                });
            }
        } catch (error) {
            console.error('Error deleting user:', error);
            toast.error('Ha ocurrido un error' + error, {
                position: 'top-center',
            });
        }
    }
    return deleteUserAuth0;
}