import { useEffect, useState } from "react"
import { IEventos } from "../../interfaces/IEventos"
import { Action, Column } from "../../interfaces/CamposTablaGenerica"
import { handleRequest } from "../FuncionRequest/FuncionRequest"
import { Button, Col, Container, Modal, Row } from "react-bootstrap"
import AddEventoModal from "./AddEventoModal"
import EditEventoModal from "./EditEventoModal"
import { useAuth0 } from "@auth0/auth0-react"
import SkeletonCard from "../../components/SkeletonCard/SkeletonCard"
import EventosPage from "../EventosPage/EventosPage"
import { sortEventosByDate } from "../../utils/dateUtils"
import { useSpinner } from "../../contexts/SpinnerProvider"

const Eventos = () => {
  const [eventos, setEventos] = useState<IEventos[]>([])
  const { addLoading, removeLoading } = useSpinner()
  const [skeletonLoading, setSkeletonLoading] = useState(false)
  const [addModalShow, setAddModalShow] = useState(false)
  const [editModalShow, setEditModalShow] = useState(false)
  const [selectedEvento, setSelectedEvento] = useState<IEventos | null>(null)
  const API_URL = process.env.REACT_APP_API_SERVER_URL_EVENTOS || ""
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [selectedToDelete, setSelectedToDelete] = useState<IEventos | null>(
    null
  )

  const { getAccessTokenSilently } = useAuth0()

  // Función para realizar solicitudes a la API con el token JWT
  const callApiWithToken = async (
    method: string,
    endpoint: string,
    body?: object
  ) => {
    const token = await getAccessTokenSilently()
    try {
      if (method === "GET") {
        const response = await fetch(endpoint, {
          method: method,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        if (response.ok) {
          const data = await response.json()
          return data
        } else {
          throw new Error("Error al realizar la solicitud")
        }
      } else {
        const response = await fetch(endpoint, {
          method: method,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })

        if (response.ok) {
          const data = await response.json()
          return data
        } else {
          throw new Error("Error al realizar la solicitud")
        }
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const callApiAndSetEventos = async () => {
    setSkeletonLoading(true)
    try {
      const responseData: IEventos[] = await callApiWithToken("GET", API_URL)
      const sortedData = sortEventosByDate(responseData)
      setEventos(sortedData)
    } catch (error) {
      console.error(error)
    } finally {
      setSkeletonLoading(false)
    }
  }

  useEffect(() => {
    callApiAndSetEventos()
  }, [API_URL])

  const actions: Action = {
    create: true,
    update: true,
    delete: true,
  }

  const columns: Column<IEventos>[] = [
    { title: "Nombre", field: "nombre" },
    { title: "Fecha", field: "fecha" },
  ]

  const handleEventoAdd = async (evento: IEventos) => {
    addLoading()
    try {
      const token = await getAccessTokenSilently()
      const newEvento = await handleRequest("POST", API_URL, evento, token)
      if (newEvento) setEventos([...eventos, newEvento])
      callApiAndSetEventos()
    } catch (error) {
      console.error(error)
    } finally {
      removeLoading()
    }
  }

  const handleEventoEdit = async (evento: IEventos) => {
    addLoading()
    try {
      const token = await getAccessTokenSilently()
      const updatedEvento = await handleRequest(
        "PUT",
        `${API_URL}/${evento.id}`,
        evento,
        token
      )
      if (updatedEvento) {
        const newData = eventos.map((item) =>
          item.id === evento.id ? updatedEvento : item
        )
        setEventos(newData)
        callApiAndSetEventos()
      }
    } catch (error) {
      console.error(error)
    } finally {
      removeLoading()
    }
  }

  const showDeleteConfirmation = (item: IEventos) => {
    setSelectedToDelete(item)
    setDeleteConfirmation(true)
  }

  const handleDeleteConfirmation = async (confirmed: boolean) => {
    if (confirmed && selectedToDelete) {
      const eventoId: number = selectedToDelete.id
      addLoading()
      try {
        const token = await getAccessTokenSilently()
        await handleRequest("DELETE", `${API_URL}/${eventoId}`, null, token)
        setEventos(eventos.filter((item) => item.id !== eventoId))
      } catch (error) {
        console.error(error)
      } finally {
        removeLoading()
      }
    }
    setSelectedToDelete(null)
    setDeleteConfirmation(false)
  }

  const handleAddModalOpen = () => setAddModalShow(true)
  const handleAddModalClose = () => setAddModalShow(false)

  const handleEditModalOpen = (item: IEventos) => {
    setSelectedEvento(item)
    setEditModalShow(true)
  }
  const handleEditModalClose = () => {
    setEditModalShow(false)
    setSelectedEvento(null)
  }

  return (
    <Container fluid>
      <h1 className="display-4">Eventos</h1>
      {skeletonLoading ? (
        <SkeletonCard />
      ) : (
        <>
          <Row className="mt-1">
            <Col sm={12}>
              <EventosPage
                data={eventos}
                columns={columns}
                actions={actions}
                onAdd={handleAddModalOpen}
                onUpdate={handleEditModalOpen}
                onDelete={showDeleteConfirmation}
              />

              {deleteConfirmation && (
                <Modal
                  show={deleteConfirmation}
                  onHide={() => setDeleteConfirmation(false)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Confirmar eliminación</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>⚠️ Atención!</p>
                    <p>
                      Si borra este evento tambien se borraran los egresados
                      relacionados <strong>de forma permanente.</strong>
                    </p>
                    <p>¿Estás seguro de que deseas eliminar este evento?</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => handleDeleteConfirmation(false)}
                    >
                      No
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteConfirmation(true)}
                    >
                      Sí
                    </Button>
                  </Modal.Footer>
                </Modal>
              )}

              <AddEventoModal
                show={addModalShow}
                handleClose={handleAddModalClose}
                handleEventoAdd={handleEventoAdd}
              />

              <EditEventoModal
                show={editModalShow}
                handleClose={handleEditModalClose}
                handleEventoEdit={handleEventoEdit}
                selectedEvento={selectedEvento}
              />
            </Col>
          </Row>
        </>
      )}
    </Container>
  )
}

export default Eventos
