import React, { useState, useEffect } from "react"
import { Modal, Button, Form, Row, Col } from "react-bootstrap"
import { IEventos, EditEventoModalProps } from "../../interfaces/IEventos"
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react"
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs, { Dayjs } from "dayjs"
import "dayjs/locale/es"
import "./EditEventoModal.css"

const EditEventoModal: React.FC<EditEventoModalProps> = ({
  show,
  handleClose,
  handleEventoEdit,
  selectedEvento,
}) => {
  const [nombre, setNombre] = useState("")
  const [fecha, setFecha] = useState<Dayjs | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isFechaValid, setIsFechaValid] = useState(true)

  const API_URL = process.env.REACT_APP_API_SERVER_URL_EVENTOS || ""
  const { getAccessTokenSilently } = useAuth0()
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (selectedEvento) {
      setNombre(selectedEvento.nombre)
      setFecha(dayjs(selectedEvento.fecha))
    }
  }, [selectedEvento])

  const resetForm = () => {
    setNombre("")
    setFecha(null)
    setIsSubmitting(false)
    setIsFechaValid(true)
  }

  const handleCloseModal = () => {
    resetForm()
    handleClose()
  }

  const handleCancelar = () => {
    resetForm()
    handleClose()
  }

  const handleFechaChange = (newValue: Dayjs | null) => {
    if (newValue) {
      setFecha(newValue)
      setIsFechaValid(true) // Marca como válido si hay una fecha seleccionada
    } else {
      setFecha(null)
      setIsFechaValid(false) // Marca como inválido si no hay fecha
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    // Validar la fecha antes de enviar
    if (!isFechaValid || !fecha) {
      setIsFechaValid(false)
      return
    }

    setIsSubmitting(true)

    if (selectedEvento) {
      const updatedEvento: IEventos = {
        ...selectedEvento,
        nombre,
        fecha: fecha.format("YYYY-MM-DD"),
      }

      try {
        const token = await getAccessTokenSilently()
        await axios.put(`${API_URL}/${selectedEvento.id}`, updatedEvento, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        handleEventoEdit(updatedEvento)
        handleClose()
      } catch (error) {
        console.error("Error al actualizar el evento:", error)
      } finally {
        setIsSubmitting(false)
      }
    }
  }

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto"
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`
    }
  }, [nombre])

  return (
    <Modal show={show} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Editar Evento</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formNombre">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Ingrese nombre"
                  value={nombre}
                  onChange={(event) => setNombre(event.target.value)}
                  className="form-control-lg custom-placeholder-edit"
                  required
                  ref={textAreaRef}
                  style={{ resize: "none" }}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formFecha">
                <Form.Label>Fecha</Form.Label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Seleccionar Fecha"
                    value={fecha ? dayjs(fecha) : null}
                    onChange={handleFechaChange}
                    onError={(error) => {
                      setIsFechaValid(!error) // Actualiza la validez en base al error
                    }}
                    format="DD/MM/YYYY"
                    className="ms-4 date-picker form-control-lg"
                  />
                </LocalizationProvider>
                {!isFechaValid && (
                  <Form.Text className="text-danger">
                    Este campo es obligatorio y debe ser una fecha válida
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelar}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Guardar Cambios
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default EditEventoModal
