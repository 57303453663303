import { FC, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import GeneradorQR from "../GeneradorQR/GeneradorQR"
import Bienvenida from "../Bienvenida/Bienvenida"
import DondeEstamos from "../DondeEstamos/DondeEstamos"
import BlockedModal from "../BlockedModal/BlockedModal"
import SobreNosotros from "../SobreNosotros/SobreNosotros"

const Home: FC = () => {
  const [showScrollButton, setShowScrollButton] = useState(false)
  const [showBlockedModal, setShowBlockedModal] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (location.search.includes("error_description=user%20is%20blocked")) {
      setShowBlockedModal(true)
    }
  }, [location.search])

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      setShowScrollButton(scrollTop > 0)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1))
      if (element) {
        element.scrollIntoView({ behavior: "smooth" })
      }
    }
  }, [location])

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <div style={{ paddingTop: "60px" }}>
      {showBlockedModal && (
        <BlockedModal
          show={showBlockedModal}
          onClose={() => setShowBlockedModal(false)}
        />
      )}
      <Bienvenida />
      <div id="generador-qr">
        <GeneradorQR />
      </div>
      <div id="sobre-nosotros">
        <SobreNosotros />
      </div>
      <div id="donde-estamos">
        <DondeEstamos />
      </div>
      {showScrollButton && (
        <div className="position-fixed bottom-0 end-0 mb-3 me-3">
          <button
            className="btn btn-primary btn-sm"
            onClick={handleScrollToTop}
          >
            <i className="bi bi-caret-up-fill fs-4"></i>
          </button>
        </div>
      )}
    </div>
  )
}

export default Home
