import { FC } from "react";
import OpcionesAdmin from "./OpcionesAdmin";

const AdminPage: FC = () => {

    return (
        <div>
            <OpcionesAdmin />
        </div>
    )
}
export default AdminPage;