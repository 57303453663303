import { Typography } from "@mui/material"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const ComoFunc: React.FC = () => {
  return (
    // Contenedor principal con imagen de fondo
    <div
      style={{
        marginBottom: "-35px",
      }}
    >
      <div className="d-flex align-items-center justify-content-center">
        <Container>
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            className="mb-4 mt-2"
          >
            Generar código QR
          </Typography>
          <Row className="justify-content-center">
            <Col lg={4} md={6} xs={12} className="text-center">
              <div
                className="circle-div bg-light d-flex flex-column align-items-center justify-content-center mb-3 rounded-circle mx-auto border border-dark shadow-lg"
                style={{ width: "70px", height: "70px" }}
              >
                <i
                  className="bi bi-clipboard-data"
                  style={{ fontSize: "2.5rem" }}
                ></i>
              </div>
              <div>
                <h5 className="fs-5">1-Ingreso de Datos</h5>
                <p className="fs-6">
                  Ingresá el DNI y contraseña del Egresado previamente dada.
                </p>
              </div>
            </Col>
            <Col lg={4} md={6} xs={12} className="text-center">
              <div
                className="circle-div bg-light d-flex flex-column align-items-center justify-content-center mb-3 rounded-circle mx-auto border border-dark shadow-lg"
                style={{ width: "70px", height: "70px" }}
              >
                <i
                  className="bi bi-hand-index-thumb"
                  style={{ fontSize: "2.5rem" }}
                />
              </div>
              <div>
                <h5 className="fs-5">2-Generá el código QR</h5>
                <p className="fs-6">
                  Hacé click en Generar QR y no le compartas el QR a quienes no
                  sean tus invitados.
                </p>
              </div>
            </Col>
            <Col lg={4} md={6} xs={12} className="text-center">
              <div
                className="circle-div bg-light d-flex flex-column align-items-center justify-content-center mb-3 rounded-circle mx-auto border border-dark shadow-lg"
                style={{ width: "70px", height: "70px" }}
              >
                <i
                  className="bi bi-qr-code-scan"
                  style={{ fontSize: "2.5rem" }}
                ></i>
              </div>
              <div>
                <h5 className="fs-5">3-Ingreso con QR</h5>
                <p className="fs-6">
                  Para ingresar mostrá el QR a escanear en la entrada, se va a
                  poder ingresar la misma cantidad de veces según la cantidad de
                  tarjetas pagadas.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default ComoFunc
