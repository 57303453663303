import React from "react";
import './SkeletonGrid.css'; // Asegúrate de crear este archivo CSS

const SkeletonGrid: React.FC = () => {
  return (
    <div className="skeleton-grid">
      {[...Array(4)].map((_, index) => (
        <div className="skeleton-row" key={index}>
          <div className="skeleton-item" />
          <div className="skeleton-item" />
          <div className="skeleton-item" />
          <div className="skeleton-item" />
        </div>
      ))}
    </div>
  );
};

export default SkeletonGrid;
