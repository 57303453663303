import React, { useState } from "react"
import { Modal, Button } from "react-bootstrap"
import { ImportExcelModalProps } from "../../interfaces/IEgresados"
import { read, utils } from "xlsx"
import "./ImportExcelModal.css"
import { IEgresados } from "../../interfaces/IEgresados"
import { Alert, CircularProgress, Snackbar } from "@mui/material"
import { useAuth0 } from "@auth0/auth0-react"
import axios from "axios"
import FileUploadButton from "../FileUploadButton/FileUploadButton"
import SpinnerOverlay from "../SpinnerOverlay/SpinnerOverlay"
import { generatePassword } from "../../utils/claveAndEncryptUtils"

const ImportExcelModal: React.FC<ImportExcelModalProps> = ({
  show,
  handleClose,
  handleImport,
  idEvento,
}) => {
  const [excelData, setExcelData] = useState<IEgresados[]>([])
  const [errors, setErrors] = useState<any[]>([])
  const fileType = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ]
  const [loadingDnis, setLoadingDnis] = useState<boolean[]>([])
  const [globalLoading, setGlobalLoading] = useState(false)
  const { getAccessTokenSilently } = useAuth0()
  const API_URL = process.env.REACT_APP_API_SERVER_URL_EGRE || ""
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarErrorOpen, setSnackbarErrorOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>("")
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined)

  const handleChange = async (file: File) => {
    setSelectedFile(file)
    if (file && fileType.includes(file.type)) {
      const reader = new FileReader()
      reader.onload = async (e) => {
        const workbook = read(e.target?.result)
        const sheet = workbook.SheetNames
        if (sheet.length) {
          const data = utils.sheet_to_json(workbook.Sheets[sheet[0]])
          const updatedData = data.map((item: any) => {
            const [apellido, nombre] = (item["NOMBRE_DEL_EGRESADO"] || "")
              .split(",")
              .map((str: string) => str.trim())
            const dni = item["DNI"] || 0
            const clave = generatePassword(apellido, dni)
            return {
              id: item["ID"] || 0,
              nombre: nombre || "",
              apellido: apellido || "",
              dni: dni,
              clave: clave,
              tarjetasPagadas:
                (item["EGRESADOS"] || 0) + (item["MAYORES"] || 0),
              tarjetasPagadasMenores: item["MENORES"] || 0,
              telefono: String(item["TELEFONO"] || ""),
              email: item["EMAIL"] || "",
              menuOpcional: String(item["MENU_OPCIONAL"] || ""),
              observaciones: String(item["OBSERVACIONES"] || ""),
              eventoId: parseInt(idEvento),
            }
          })
          setExcelData(updatedData)
          validateData(updatedData)
        }
      }
      reader.readAsArrayBuffer(file)
    } else {
      handleError("Por favor, suba un archivo en formato Excel.")
    }
  }
  const handleError = (message: string) => {
    setSnackbarMessage(message)
    setSnackbarErrorOpen(true)
  }

  const validateDNI = async (dni: string, index: number) => {
    setLoadingDnis((prev) => {
      const newLoading = [...prev]
      newLoading[index] = true
      return newLoading
    })
    try {
      const token = await getAccessTokenSilently()
      const response = await axios.post(
        `${API_URL}/validarDNI`,
        [parseInt(dni)],
        { headers: { Authorization: `Bearer ${token}` } }
      )

      const dniExistentes = response.data.dniExistentes
      setErrors((prevErrors) => {
        const newErrors = [...prevErrors]
        if (dniExistentes.length > 0) {
          newErrors[index] = {
            ...newErrors[index],
            dni: "Este DNI ya existe en la base de datos.",
          }
        } else {
          newErrors[index] = { ...newErrors[index], dni: undefined }
        }
        return newErrors
      })
    } catch (error) {
      console.error("Error al verificar el DNI:", error)
      setErrors((prevErrors) => {
        const newErrors = [...prevErrors]
        newErrors[index] = { ...newErrors[index], dni: undefined }
        return newErrors
      })
    } finally {
      setLoadingDnis((prev) => {
        const newLoading = [...prev]
        newLoading[index] = false // Desmarca la fila como cargando
        return newLoading
      })
    }
  }

  const obtenerDnisDuplicados = (egresados: IEgresados[]): number[] => {
    const dniSet = new Set<number>()
    const dnisDuplicados: number[] = []

    for (const egresado of egresados) {
      if (dniSet.has(egresado.dni)) {
        if (!dnisDuplicados.includes(egresado.dni)) {
          dnisDuplicados.push(egresado.dni)
        }
      } else {
        dniSet.add(egresado.dni)
      }
    }

    return dnisDuplicados
  }

  const validateData = (data: IEgresados[]) => {
    const dniDuplicadosEnExcel = obtenerDnisDuplicados(data)
    const newErrors: any[] = []

    if (dniDuplicadosEnExcel.length > 0) {
      setSnackbarMessage(
        `Los siguientes DNI están duplicados: ${dniDuplicadosEnExcel.join(
          ", "
        )}`
      )
      setSnackbarOpen(true)
    } else {
      setSnackbarMessage("")
      setSnackbarOpen(false)
    }

    // Agregar errores por DNI duplicados
    data.forEach((item, index) => {
      const errors: any = {}

      // Verificar si el DNI está duplicado
      if (dniDuplicadosEnExcel.includes(item.dni)) {
        errors.dni = "Este DNI está duplicado."
      }

      // Verificar otros campos
      if (!item.nombre) errors.nombre = "El nombre es requerido."
      if (!item.apellido) errors.apellido = "El apellido es requerido."
      if (item.dni.toString().length !== 8)
        errors.dni = "El DNI debe tener 8 caracteres."
      if (item.clave.includes("-"))
        errors.clave = "La clave no debe contener el carácter '-'"

      // Agregar los errores encontrados
      newErrors[index] = errors
    })

    setErrors(newErrors)
  }

  const handleInputChange = (
    index: number,
    field: keyof IEgresados,
    value: string | number
  ) => {
    const newData: IEgresados[] = [...excelData]
    if (field === "dni") {
      value = parseInt(value as string, 10) || 0
      if (value.toString().length === 8) {
        validateDNI(value.toString(), index)
        validateData(newData)
      }
    }

    if (field === "telefono") {
      value = String(value)
    }

    newData[index] = { ...newData[index], [field]: value }

    if (field === "apellido" || field === "dni") {
      const apellido = field === "apellido" ? value : newData[index].apellido
      const dni = field === "dni" ? value : newData[index].dni
      newData[index].clave = generatePassword(apellido as string, dni as string)
    }

    setExcelData(newData)
    validateData(newData)
  }

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return
    }
    setSnackbarOpen(false)
    setSnackbarErrorOpen(false)
  }

  const handleImportData = () => {
    if (
      errors.every(
        (err) =>
          Object.keys(err).length === 0 ||
          Object.values(err).every((value) => value === undefined)
      )
    ) {
      handleImport(excelData)
      handleSnackbarClose()
      handleClose()
    } else {
      setSnackbarMessage("Corrija los errores antes de importar.")
      setSnackbarOpen(true)
    }
  }

  const closeModal = () => {
    setExcelData([])
    setErrors([])
    setSelectedFile(undefined)
    handleClose()
    handleSnackbarClose()
  }

  return (
    <>
      <Modal show={show} onHide={closeModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Importar Excel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {globalLoading && <SpinnerOverlay />}
          <div style={{ display: "flex", alignItems: "center" }}>
            <FileUploadButton
              onFileChange={handleChange}
              onError={handleError}
            />
            {selectedFile && (
              <p style={{ marginLeft: "10px", marginTop: "10px" }}>
                Archivo seleccionado: {selectedFile.name}
              </p>
            )}
          </div>
          <div className="data-table-container">
            <table className="table">
              <thead>
                <tr className="trhead">
                  <th>Nº</th>
                  <th>Apellido</th>
                  <th>Nombre</th>
                  <th>DNI</th>
                  <th>Tarjetas Pagadas</th>
                  <th>Tarjetas Pagadas Menores</th>
                  <th>Contraseña</th>
                  <th>Email</th>
                  <th>Teléfono</th>
                  <th>Menú Opcional</th>
                  <th>Observaciones</th>
                </tr>
              </thead>
              <tbody>
                {excelData.length ? (
                  excelData.map((info, index) => {
                    const hasErrors =
                      Object.keys(errors[index] || {}).length > 0

                    return (
                      <tr key={index} className={hasErrors ? "error-row" : ""}>
                        <td className={`input-cell`}>{index + 1}</td>
                        <td
                          className={`input-cell ${
                            errors[index]?.apellido ? "error" : ""
                          }`}
                        >
                          <input
                            type="text"
                            value={info.apellido}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "apellido",
                                e.target.value
                              )
                            }
                            className={`form-control ${
                              errors[index]?.apellido ? "error-field" : ""
                            }`}
                          />
                          {errors[index]?.apellido && (
                            <div className="error-message">
                              {errors[index].apellido}
                            </div>
                          )}
                        </td>
                        <td
                          className={`input-cell ${
                            errors[index]?.nombre ? "error" : ""
                          }`}
                        >
                          <input
                            type="text"
                            value={info.nombre}
                            onChange={(e) =>
                              handleInputChange(index, "nombre", e.target.value)
                            }
                            className={`form-control ${
                              errors[index]?.nombre ? "error-field" : ""
                            }`}
                          />
                          {errors[index]?.nombre && (
                            <div className="error-message">
                              {errors[index].nombre}
                            </div>
                          )}
                        </td>
                        <td
                          className={`input-cell ${
                            errors[index]?.dni ? "error" : ""
                          }`}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <input
                              type="text"
                              value={info.dni}
                              onChange={(e) =>
                                handleInputChange(index, "dni", e.target.value)
                              }
                              className={`form-control ${
                                errors[index]?.dni ? "error-field" : ""
                              }`}
                              style={{ flex: 1 }}
                            />
                            {loadingDnis[index] && (
                              <CircularProgress
                                size={24}
                                style={{ marginLeft: "10px" }}
                              />
                            )}
                          </div>
                          {errors[index]?.dni && (
                            <div className="error-message">
                              {errors[index].dni}
                            </div>
                          )}
                        </td>
                        <td
                          className={`input-cell ${
                            errors[index]?.tarjetasPagadas ? "error" : ""
                          }`}
                        >
                          <input
                            type="text"
                            value={info.tarjetasPagadas}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "tarjetasPagadas",
                                e.target.value
                              )
                            }
                            className={`form-control ${
                              errors[index]?.tarjetasPagadas
                                ? "error-field"
                                : ""
                            }`}
                          />
                          {errors[index]?.tarjetasPagadas && (
                            <div className="error-message">
                              {errors[index].tarjetasPagadas}
                            </div>
                          )}
                        </td>
                        <td
                          className={`input-cell ${
                            errors[index]?.tarjetasPagadasMenores ? "error" : ""
                          }`}
                        >
                          <input
                            type="text"
                            value={info.tarjetasPagadasMenores}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "tarjetasPagadasMenores",
                                e.target.value
                              )
                            }
                            className={`form-control ${
                              errors[index]?.tarjetasPagadasMenores
                                ? "error-field"
                                : ""
                            }`}
                          />
                          {errors[index]?.tarjetasPagadasMenores && (
                            <div className="error-message">
                              {errors[index].tarjetasPagadasMenores}
                            </div>
                          )}
                        </td>
                        <td
                          className={`input-cell ${
                            errors[index]?.clave ? "error" : ""
                          }`}
                        >
                          <input
                            type="text"
                            value={info.clave}
                            onChange={(e) =>
                              handleInputChange(index, "clave", e.target.value)
                            }
                            className={`form-control ${
                              errors[index]?.clave ? "error-field" : ""
                            }`}
                          />
                          {errors[index]?.clave && (
                            <div className="error-message">
                              {errors[index].clave}
                            </div>
                          )}
                        </td>
                        <td
                          className={`input-cell ${
                            errors[index]?.email ? "error" : ""
                          }`}
                        >
                          <input
                            type="email"
                            value={info.email}
                            onChange={(e) =>
                              handleInputChange(index, "email", e.target.value)
                            }
                            className={`form-control ${
                              errors[index]?.email ? "error-field" : ""
                            }`}
                          />
                          {errors[index]?.email && (
                            <div className="error-message">
                              {errors[index].email}
                            </div>
                          )}
                        </td>
                        <td
                          className={`input-cell ${
                            errors[index]?.telefono ? "error" : ""
                          }`}
                        >
                          <input
                            type="text"
                            value={info.telefono}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "telefono",
                                e.target.value
                              )
                            }
                            className={`form-control ${
                              errors[index]?.telefono ? "error-field" : ""
                            }`}
                          />
                          {errors[index]?.telefono && (
                            <div className="error-message">
                              {errors[index].telefono}
                            </div>
                          )}
                        </td>
                        <td
                          className={`input-cell ${
                            errors[index]?.menuOpcional ? "error" : ""
                          }`}
                        >
                          <input
                            type="text"
                            value={info.menuOpcional}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "menuOpcional",
                                e.target.value
                              )
                            }
                            className={`form-control ${
                              errors[index]?.menuOpcional ? "error-field" : ""
                            }`}
                          />
                        </td>
                        <td
                          className={`input-cell ${
                            errors[index]?.observaciones ? "error" : ""
                          }`}
                        >
                          <input
                            type="text"
                            value={info.observaciones}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "observaciones",
                                e.target.value
                              )
                            }
                            className={`form-control ${
                              errors[index]?.observaciones ? "error-field" : ""
                            }`}
                          />
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={7}>No hay datos disponibles</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <Snackbar
            open={snackbarOpen}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert onClose={handleSnackbarClose} severity="warning">
              {snackbarMessage}
            </Alert>
          </Snackbar>
          <Snackbar
            open={snackbarErrorOpen}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert onClose={handleSnackbarClose} severity="error">
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Cerrar
          </Button>
          <Button
            variant="primary"
            onClick={handleImportData}
            disabled={!selectedFile}
          >
            Importar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ImportExcelModal
