import React from "react"
import { Modal, Button } from "react-bootstrap"
import "./QRMessage.css"

interface QRMessageProps {
  message: string
  variant: string
  tarjetasPagadas: string
  tarjetasPorIngresar: string
  onClose: () => void
}

const QRMessage: React.FC<QRMessageProps> = ({
  message,
  variant,
  tarjetasPagadas,
  tarjetasPorIngresar,
  onClose,
}) => {
  return (
    <Modal
      show={true}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      centered
      style={{
        boxShadow: `0 0 10px ${variant === "danger" ? "red" : "green"}`,
      }}
    >
      <Modal.Header
        style={{
          backgroundColor: variant === "danger" ? "red" : "green",
          color: "white",
        }}
      >
        <Modal.Title
          className="display-6 text-center"
          style={{ margin: "0 auto" }}
        >
          Da Vinci Ingreso
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="fs-2" style={{ whiteSpace: "pre-line" }}>
          <span className="container-message-qr"> {message}</span>
          {tarjetasPorIngresar && (
            <>
              <span className="span-quantity-cards container-message-qr">
                {" " + tarjetasPorIngresar || ""}
              </span>
              <span className="container-message-qr">{` de ${tarjetasPagadas} pagadas.`}</span>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button
          variant="danger"
          className="boton-close-modal"
          onClick={onClose}
        >
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default QRMessage
