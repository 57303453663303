import { CircularProgress } from "@mui/material"
import React from "react"
import "./SpinnerOverlay.css"

const SpinnerOverlay: React.FC = ({}) => {
  return (
    <div className="spinner-overlay">
      <CircularProgress />
    </div>
  )
}

export default SpinnerOverlay
