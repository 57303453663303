import CryptoJS from "crypto-js"
import { IEgresados } from "../interfaces/IEgresados"

const key1 = process.env.REACT_APP_API_SECRET_KEY || ""
const keyIV1 = process.env.REACT_APP_API_IV || ""

const key2 = process.env.REACT_APP_API_SECRET_KEY2 || ""
const keyIV2 = process.env.REACT_APP_API_IV2 || ""

const secretKey = CryptoJS.enc.Utf8.parse(key1)
const iv = CryptoJS.enc.Utf8.parse(keyIV1)

const secretKey2 = CryptoJS.enc.Utf8.parse(key2)
const iv2 = CryptoJS.enc.Utf8.parse(keyIV2)

export const encryptText = (plainText: string): string => {
  const encrypted = CryptoJS.AES.encrypt(plainText, secretKey, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })
  return encrypted.toString()
}

export const encryptTextToHide = (plainText: string): string => {
  const encrypted = CryptoJS.AES.encrypt(plainText, secretKey2, {
    iv: iv2,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })
  return encrypted.toString()
}

export const generatePassword = (apellido: string, dni: string) => {
  const dniStr = String(dni).padStart(8, "0")
  const ultimos6DNI = dniStr.slice(-6)
  let clave = `${encryptTextToHide(`${apellido}${ultimos6DNI}`)}`
  if (clave.length > 24) {
    clave = clave.substring(clave.length - 24)
  }
  return clave
}

export const copyDataEgresado = (
  egresado: IEgresados,
  setCopyMessage: React.Dispatch<React.SetStateAction<string>>
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const apellidoYNombre = `${egresado.apellido} ${egresado.nombre}`
    const clave = egresado.clave || ""

    const textToCopy = `
    Hola, ${apellidoYNombre}, te enviamos la web y tu clave correspondiente para que descargues tus códigos QR\n 
    davincifesta.com\n
    Clave: ${clave}`

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        const message = `Clave de ${apellidoYNombre} copiada al portapapeles`
        setCopyMessage(message) // Establece el mensaje en el estado

        // Cerrar el mensaje después de 3 segundos
        setTimeout(() => {
          setCopyMessage("") // Limpiar el mensaje
        }, 3000)

        resolve() // Resolvemos la promesa
      })
      .catch((err) => {
        console.error("Error al copiar al portapapeles: ", err)
        reject(err) // Rechazamos en caso de error
      })
  })
}
