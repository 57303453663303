import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import "bootstrap/dist/css/bootstrap.min.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { Auth0Provider } from "@auth0/auth0-react"
import SpinnerProvider from "./contexts/SpinnerProvider"

const DOMAIN = process.env.REACT_APP_DOMAIN
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
const AUDIENCE = process.env.REACT_APP_AUDIENCE

// Comprobar si las variables de entorno están definidas
if (!DOMAIN || !CLIENT_ID || !AUDIENCE) {
  throw new Error("Las variables de entorno no están definidas.")
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={DOMAIN}
      clientId={CLIENT_ID}
      authorizationParams={{
        audience: AUDIENCE,
        redirect_uri: window.location.origin,
      }}
      cacheLocation="localstorage"
    >
      <SpinnerProvider>
        <App />
      </SpinnerProvider>
    </Auth0Provider>
  </React.StrictMode>
)

reportWebVitals()
