import React, { FC } from "react"
import { useAuth0 } from "@auth0/auth0-react"

/**
 * Botón para cerrar sesión utilizando Auth0.
 */
const LogoutButton: FC = () => {
  const { logout } = useAuth0()

  const buttonStyle = {
    padding: "0.0rem 0.7rem",
    margin: "0.0rem",
  }

  return (
    <button
      className="btn btn-primary"
      style={buttonStyle}
      onClick={() =>
        logout({ logoutParams: { returnTo: window.location.origin } })
      }
    >
      Cerrar sesión
    </button>
  )
}

export default LogoutButton
