import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  const googleMapsUrl = "https://www.google.com/maps/dir//Da+Vinci+Festa+Bousquet+Isidoro+M5573+Jun%C3%ADn+Mendoza/@-33.1234043,-68.4819416,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0x967e5d59a4f54c3f:0x8a86a6a5132396eb!2m2!1d-68.4819416!2d-33.1234043?entry=ttu";

  return (
    <footer className="bg-dark py-5">
      <Container>
        <Row className="align-items-center">
          {/* Columna izquierda con información de derechos de autor */}
          <Col xs={12} className="text-center text-md-center">
            <p className="mb-0 text-white fs-4">© {new Date().getFullYear()} Da Vinci Festa</p>
            <p className='text-white'>Creadores de Alegrías</p>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center">
          {/* Enlaces de WhatsApp */}
          <Col md="3">
            <li className="mt-2">
              <i className="bi bi-whatsapp text-white"></i>
              <a
                href={`https://wa.me/5492634286422`}
                target="_blank"
                rel="noreferrer"
                className="footer-link text-white px-1"
              >
                <span className="d-sm-none">+54 9 263 4286422</span>
                <span className="d-none d-sm-inline">+54 9 263 4286422</span>
              </a>
            </li>
            <li className="mt-2">
              <i className="bi bi-whatsapp text-white"></i>
              <a
                href={`https://wa.me/5492634271251`}
                target="_blank"
                rel="noreferrer"
                className="footer-link text-white px-1"
              >
                <span className="d-sm-none">+54 9 263 4271251</span>
                <span className="d-none d-sm-inline">+54 9 263 4271251</span>
              </a>
            </li>
            <li className="mt-2">
              <i className="bi bi-whatsapp text-white"></i>
              <a
                href={`https://wa.me/5492634308973`}
                target="_blank"
                rel="noreferrer"
                className="footer-link text-white px-1"
              >
                <span className="d-sm-none">+54 9 263 4308973</span>
                <span className="d-none d-sm-inline">+54 9 263 4308973</span>
              </a>
            </li>
          </Col>
          {/* Enlace de ubicación */}
          <Col md="3" className='mt-3'>
            <li className="">
              <i className="bi bi-geo-alt text-white"></i>
              <a
                href={googleMapsUrl}
                target="_blank"
                rel="noreferrer"
                className="footer-link text-white px-1"
              >
                <span className="footer-text text-white">
                  Da Vinci Festa, Bousquet Isidoro, M5573 Junín, Mendoza
                </span>
              </a>
            </li>
          </Col>
          <Col md="3" className='mt-3'>
            <li>
              <i className="bi bi-envelope text-white"></i>
              <a
                href="mailto:davincifesta@gmail.com"
                className="footer-link text-white px-1"
              >
                davincifesta@gmail.com
              </a>
            </li>
          </Col>
          <Col md="3" className='mt-3'>
            {/* Enlace a Políticas de Privacidad */}
            <li>
              <i className="bi bi-shield-lock text-white"></i>
              {/* Reemplaza "/politicas-privacidad" con el enlace correcto a tus políticas de privacidad */}
              <a href="https://www.iubenda.com/privacy-policy/41927435" className="footer-link text-white px-1">
                Políticas de Privacidad
              </a>
            </li>
            {/* Enlace a Términos de Servicio */}
            <li className='mt-3'>
              <i className="bi bi-book text-white"></i>
              {/* Reemplaza "/politica-cookies" con el enlace correcto a tus políticas de cookies */}
              <a href="https://www.iubenda.com/privacy-policy/41927435/cookie-policy" className="footer-link text-white px-1">
                Política de Cookies
              </a>
            </li>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
