import { Route, Routes, HashRouter } from "react-router-dom"
import Admin from "../screens/Admin"
import UserRouter from "./UserRouter"
import Page404 from "../components/Page404/Page404"
import NavBar from "../components/NavBar/NavBar"
import Empleado from "../screens/Empleado"
import Egresados from "../components/Egresados/Egresados"
import { withAuthenticationRequired } from "@auth0/auth0-react"

const IndexRouter = () => {
  const AdminRoute = withAuthenticationRequired(Admin, {
    onRedirecting: () => <div>Loading...</div>,
  })

  const EmpleadoRoute = withAuthenticationRequired(Empleado, {
    onRedirecting: () => <div>Loading...</div>,
  })

  const EgresadosRoute = withAuthenticationRequired(Egresados, {
    onRedirecting: () => <div>Loading...</div>,
  })

  return (
    <HashRouter>
      <NavBar />
      <Routes>
        <Route path="/*" element={<UserRouter />} />
        <Route path="/admin/*" element={<AdminRoute />} />
        <Route path="/empleado" element={<EmpleadoRoute />} />
        <Route path="/egresados/:id" element={<EgresadosRoute />} />{" "}
        <Route path="*" element={<Page404 />} />
      </Routes>
    </HashRouter>
  )
}

export default IndexRouter
