import React from "react";
import './SkeletonCard.css'; // Asegúrate de crear este archivo CSS

const SkeletonCard: React.FC = () => {
  return (
    <div className="skeleton-card">
      {[...Array(4)].map((_, index) => (
        <div className="skeleton-card-item" key={index} />
      ))}
    </div>
  );
};

export default SkeletonCard;
