import React from "react"

interface SpinnerProps {
  height?: string
  marginTop?: string
  marginBottom?: string
  color?: string
}

const Spinner: React.FC<SpinnerProps> = ({
  height = "80%",
  marginTop = "0",
  marginBottom = "0",
  color = "#2979ff",
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: height,
        width: "100%",
        marginTop: marginTop,
        marginBottom: marginBottom,
      }}
    >
      <div className="text-center">
        <div
          className="spinner-border"
          role="status"
          style={{ width: "40px", height: "40px", color: color }}
        >
          <span className="visually-hidden">Cargando...</span>
        </div>
      </div>
    </div>
  )
}

export default Spinner
