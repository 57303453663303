import React, { useState, useEffect } from "react"
import { IEmpleados } from "../../interfaces/IEmpleados"
import { Action, Column } from "../../interfaces/CamposTablaGenerica"
import GenericTable from "../GenericTable/GenericTable"
import { Container, Row, Col, Button, Modal } from "react-bootstrap"
import { handleRequest } from "../FuncionRequest/FuncionRequest"
import AddEmpleadoModal from "./AddEmpleadoModal"
import EditEmpleadoModal from "./EditEmpleadoModal"
import { useAuth0 } from "@auth0/auth0-react"
import { useDeleteUserAuth0 } from "../Auth0/Hooks/useDeleteUserAuth0"
import SkeletonGrid from "../../components/Skeleton/SkeletonGrid"
import { Box } from "@mui/material"
import { useSpinner } from "../../contexts/SpinnerProvider"

const Empleados = () => {
  //Estados del componente
  const [empleados, setEmpleados] = useState<IEmpleados[]>([])
  const [empleadosComplete, setEmpleadosComplete] = useState<IEmpleados[]>([])
  const [editModalShow, setEditModalShow] = useState(false)
  const [addModalShow, setAddModalShow] = useState(false)
  const [selectedUsuario, setSelectedUsuario] = useState<IEmpleados | null>(
    null
  )
  const API_URL = process.env.REACT_APP_API_SERVER_URL_EMPL || ""
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [selectedToDelete, setSelectedToDelete] = useState<IEmpleados | null>(
    null
  )
  const [selectedEditUsuario, setSelectedEditUsuario] =
    useState<IEmpleados | null>(null)
  const { getAccessTokenSilently } = useAuth0()
  const { addLoading, removeLoading } = useSpinner()
  const [skeletonLoading, setSkeletonLoading] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)

  //Custom Hooks
  const deleteUserAuth0 = useDeleteUserAuth0()

  // Función para realizar solicitudes a la API con el token JWT
  const callApiWithToken = async (
    method: string,
    endpoint: string,
    body?: object
  ) => {
    const token = await getAccessTokenSilently()
    try {
      if (method === "GET") {
        const response = await fetch(endpoint, {
          method: method,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        if (response.ok) {
          const data = await response.json()
          return data
        } else {
          throw new Error("Error al realizar la solicitud")
        }
      } else {
        const response = await fetch(endpoint, {
          method: method,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })

        if (response.ok) {
          const data = await response.json()
          return data
        } else {
          throw new Error("Error al realizar la solicitud")
        }
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const callApiAndSetEmpleados = async () => {
    setSkeletonLoading(true)
    try {
      const responseData = await callApiWithToken("GET", API_URL)

      // Actualiza el estado de egresados con los datos obtenidos
      setEmpleados(responseData)
      setEmpleadosComplete(responseData)
    } catch (error) {
      console.error(error)
    } finally {
      setSkeletonLoading(false)
    }
  }

  // Cargar datos de empleados al montar el componente y cuando cambie la URL de la API
  useEffect(() => {
    callApiAndSetEmpleados()
  }, [API_URL]) // Agregamos API_URL como dependencia para que se dispare cuando cambie

  //Acciones que se pueden realizar
  const actions: Action = {
    create: true,
    update: true,
    delete: true,
  }

  const columns: Column<IEmpleados>[] = [
    // Definir columnas de la tabla
    { title: "Nombre", field: "nombre" },
    { title: "Apellido", field: "apellido" },
    { title: "Email", field: "email" },
    { title: "Contraseña", field: "clave" },
    {
      title: "Estado",
      field: "estado",
      render: (empleado: IEmpleados) => (
        <span className={`${empleado.estado ? "text-danger" : "text-success"}`}>
          {empleado.estado ? (
            <h2>
              <i className="bi bi-lock-fill"></i>
            </h2>
          ) : (
            <h2>
              <i className="bi bi-unlock-fill "></i>
            </h2>
          )}
        </span>
      ),
    },
  ]

  // Función para mostrar el cuadro de diálogo de confirmación de eliminación
  const showDeleteConfirmation = (item: IEmpleados) => {
    setSelectedToDelete(item)
    setDeleteConfirmation(true)
  }

  // Función para busqueda personalizada por ID y nombre
  const customSearch = (searchText: string): Promise<IEmpleados[]> => {
    return new Promise((resolve) => {
      const normalizedSearchText = normalizeString(searchText)

      const filteredData = empleados.filter((empleado) => {
        const normalizedIdUsuario = normalizeString(empleado.id.toString())
        const normalizedNombre = normalizeString(empleado.nombre.toString())
        const normalizedApellido = normalizeString(empleado.apellido.toString())
        const normalizedEmail = normalizeString(empleado.email.toString())

        return (
          normalizedIdUsuario.includes(normalizedSearchText) ||
          normalizedNombre.includes(normalizedSearchText) ||
          normalizedApellido.includes(normalizedSearchText) ||
          normalizedEmail.includes(normalizedSearchText)
        )
      })

      resolve(filteredData)
    })
  }
  //Normaliza el texto para que no importe las Mayus, Minus o Tildes
  const normalizeString = (str: string): string => {
    return str
      .toLowerCase()
      .normalize("NFD") // Eliminar tildes
      .replace(/[\u0300-\u036f]/g, "")
  }

  const handleEmpleadoAdd = async (empleado: IEmpleados) => {
    addLoading()
    try {
      // Manejar la agregación de un empleado
      const token = await getAccessTokenSilently()

      const newEmpleado = await handleRequest("POST", API_URL, empleado, token)
      if (newEmpleado) {
        setEmpleados([...empleados, newEmpleado])
        if (newEmpleado) {
          setEmpleados([...empleados, newEmpleado])
          // Actualizar el usuario seleccionado para la edición con el nuevo empleado
          setSelectedUsuario(newEmpleado)
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      removeLoading()
    }
  }

  // Manejar la edición de un empleado
  const handleEmpleadoEdit = async (empleado: IEmpleados) => {
    addLoading()
    try {
      const token = await getAccessTokenSilently()
      const updatedEmpleado = await handleRequest(
        "PUT",
        `${API_URL}/${empleado.id}`,
        empleado,
        token
      )
      if (updatedEmpleado) {
        const newData = empleados.map((item) =>
          item.id === empleado.id ? updatedEmpleado : item
        )
        setEmpleados(newData)
      }
    } catch (error) {
      console.error(error)
    } finally {
      removeLoading()
    }
  }

  // Función para manejar la confirmación de eliminación
  const handleDeleteConfirmation = async (confirmed: boolean) => {
    addLoading()
    if (confirmed && selectedToDelete) {
      const empleadoId: number = selectedToDelete.id

      try {
        // Llama a la función deleteUserAuth0 para eliminar al usuario de Auth0
        await deleteUserAuth0(selectedToDelete.idAuth0) // Envía el ID del usuario a eliminar

        const token = await getAccessTokenSilently() // Obtener el token
        const deleteURL = `${API_URL}/${empleadoId}`
        await handleRequest("DELETE", deleteURL, null, token)

        setEmpleados(empleados.filter((item) => item.id !== empleadoId))
      } catch (error) {
        console.error(error)
      } finally {
        removeLoading()
      }
    }

    // Restablecer los estados
    setSelectedToDelete(null)
    setDeleteConfirmation(false)
  }

  // Abrir modal para agregar empleado
  const handleAddModalOpen = () => {
    setAddModalShow(true)
  }

  // Cerrar modal para agregar empleado
  const handleAddModalClose = () => {
    setAddModalShow(false)
  }

  // Obtener detalles de un usuario por su ID
  const usuarioRow = (id: number) => {
    const usuarioRe = empleadosComplete.find((empleado) => empleado.id === id)

    if (usuarioRe) {
      return usuarioRe
    } else {
      // Manejar el caso en el que no se encuentra el empleado con el ID específico
      return null
    }
  }

  // Abrir modal para editar empleado
  const handleEditModalOpen = (item: IEmpleados) => {
    setSelectedEditUsuario(item)
    setEditModalShow(true)
  }

  // Cerrar modal para editar empleado
  const handleEditModalClose = () => {
    setEditModalShow(false)
    setSelectedUsuario(null)
  }

  return (
    <div>
      <Container fluid>
        <h1 className="display-3" style={{ width: isMobile ? "50%" : "100%" }}>
          Lista de Empleados
        </h1>
        {skeletonLoading ? (
          <SkeletonGrid />
        ) : (
          <>
            <Row className="mt-3">
              <Col sm={12}>
                <Box>
                  <GenericTable
                    data={empleados}
                    columns={columns}
                    actions={actions}
                    onAdd={handleAddModalOpen}
                    onUpdate={handleEditModalOpen}
                    onDelete={showDeleteConfirmation}
                    customSearch={customSearch}
                    isEmployee
                  />
                </Box>
                {deleteConfirmation && (
                  <Modal
                    show={deleteConfirmation}
                    onHide={() => setDeleteConfirmation(false)}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Confirmar eliminación</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      ¿Estás seguro de que deseas eliminar este egresado de la
                      lista?
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => handleDeleteConfirmation(false)}
                      >
                        No
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => handleDeleteConfirmation(true)}
                      >
                        Sí
                      </Button>
                    </Modal.Footer>
                  </Modal>
                )}
              </Col>
            </Row>
            <AddEmpleadoModal
              show={addModalShow}
              handleClose={handleAddModalClose}
              handleEmpleadoAdd={handleEmpleadoAdd}
            />

            <EditEmpleadoModal
              show={editModalShow}
              handleClose={handleEditModalClose}
              handleEmpleadoEdit={handleEmpleadoEdit}
              selectedEmpleado={selectedEditUsuario}
            />
          </>
        )}
      </Container>
    </div>
  )
}

export default Empleados
