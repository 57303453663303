import React from "react"
import "./App.css"
import IndexRouter from "./routers/IndexRouter"
import { useAuth0 } from "@auth0/auth0-react"
import Spinner from "./components/Spinner/Spinner"

function App(): JSX.Element {
  const { isLoading } = useAuth0()

  if (isLoading) return <Spinner marginTop="10%" color="#e4e3e3" />
  return (
    <div className="App">
      <IndexRouter />
    </div>
  )
}

export default App
