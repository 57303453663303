import React, { useState, useEffect } from "react"
import { Modal, Button, Form, Row, Col } from "react-bootstrap"
import { IEmpleados, EditEmpleadoModalProps } from "../../interfaces/IEmpleados"
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react"
import { useChangeAuth0UserState } from "../Auth0/Hooks/updateAuth0UserStatus"

const EditEmpleadoModal: React.FC<EditEmpleadoModalProps> = ({
  show,
  handleClose,
  handleEmpleadoEdit,
  selectedEmpleado,
}) => {
  // Estados del componente
  const [nombre, setNombre] = useState("")
  const [apellido, setApellido] = useState("")
  const [idAuth0, setIdAuth0] = useState("")
  const [email, setEmail] = useState("")
  const [clave, setClave] = useState("")
  const [estado, setEstado] = useState(false)
  const [emailValido, setEmailValido] = useState(true)
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/
  const [emailEnUso, setEmailEnUso] = useState(false)
  const [originalEmail, setOriginalEmail] = useState("")
  const API_URL = process.env.REACT_APP_API_SERVER_URL_EMPL || ""
  const { getAccessTokenSilently } = useAuth0()
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    specialChar: false,
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [claveValida, setClaveValida] = useState(true)

  //Custom Hooks
  const updateAuth0UserStatus = useChangeAuth0UserState()

  // Restablecer el estado del formulario cuando se abre el modal de edición
  useEffect(() => {
    if (selectedEmpleado) {
      setNombre(selectedEmpleado.nombre)
      setApellido(selectedEmpleado.apellido)
      setIdAuth0(selectedEmpleado.idAuth0)
      setEmail(selectedEmpleado.email)
      setClave(selectedEmpleado.clave)
      setEstado(selectedEmpleado.estado)
      setOriginalEmail(selectedEmpleado.email) // Almacenar el email original
      setEmailValido(true)
      setEmailEnUso(false)
    }
  }, [selectedEmpleado])

  useEffect(() => {
    if (selectedEmpleado && selectedEmpleado.estado !== estado) {
      const userId = selectedEmpleado.idAuth0
      const isBlocked = estado
      updateAuth0UserStatus(userId, isBlocked)
    }
  }, [selectedEmpleado, estado, updateAuth0UserStatus])

  // Función para verificar la contraseña en tiempo real
  const checkPassword = (password: string) => {
    setPasswordValidation({
      length: password.length >= 8,
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[-_@$!%*?&]/.test(password),
    })
  }
  useEffect(() => {
    checkPassword(clave)
  }, [clave])

  const resetForm = () => {
    // Restablecer los valores del estado al valor original del selectedEgresado
    if (selectedEmpleado) {
      setNombre(selectedEmpleado.nombre)
      setApellido(selectedEmpleado.apellido)
      setIdAuth0(selectedEmpleado.idAuth0)
      setEmail(selectedEmpleado.email)
      setClave(selectedEmpleado.clave)
      setEstado(selectedEmpleado.estado)
    }
    // Restablecer otros estados si es necesario
    setEmailValido(true)
    setEmailEnUso(false)
    setIsSubmitting(false)
    setClaveValida(true)
    handleClose() // Cerrar el modal
  }

  const handleCloseModal = () => {
    resetForm() // Limpia los campos al cerrar
    handleClose()
  }

  const handleCancelar = () => {
    resetForm() // Limpia los campos al cancelar
    handleClose()
  }
  // Función para verificar si el email ya está en uso
  const checkEmailDuplicate = async (emailToCheck: string) => {
    try {
      const token = await getAccessTokenSilently()
      const response = await axios.get(API_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const empleados = response.data
      const emailExists = empleados.some(
        (empleado: any) => empleado.email === emailToCheck
      )

      return emailExists
    } catch (error) {
      console.error("Error al verificar el email:", error)
      return false
    }
  }

  // Manejar el envío del formulario
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (email !== originalEmail) {
      // Verificar si el email ha cambiado
      if (await checkEmailDuplicate(email)) {
        // El email ha cambiado, verificar si está en uso
        setEmailEnUso(true)
        return
      }
    }

    // Verificar dirección de correo electrónico
    if (!emailRegex.test(email)) {
      setEmailValido(false)
      return
    } else {
      setEmailValido(true)
    }

    // Verificar las condiciones de la contraseña
    const isPasswordValid =
      passwordValidation.length &&
      passwordValidation.lowercase &&
      passwordValidation.uppercase &&
      passwordValidation.number &&
      passwordValidation.specialChar

    if (!isPasswordValid) {
      // Si la contraseña no cumple con las condiciones, puedes mostrar un mensaje o realizar alguna acción
      setClaveValida(false)
      console.error("La contraseña no cumple con los requisitos.")
      return
    }

    setIsSubmitting(true)

    if (selectedEmpleado) {
      const updatedEmpleado: IEmpleados = {
        ...selectedEmpleado,
        nombre,
        apellido,
        email,
        clave,
        estado,
      }

      handleEmpleadoEdit(updatedEmpleado)
    }
    setEmailEnUso(false)
    setIsSubmitting(false)
    setClaveValida(true)
    handleClose()
  }

  return (
    <Modal show={show} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Editar Empleado</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formNombre">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese nombre"
                  value={nombre}
                  onChange={(event) => setNombre(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formApellido">
                <Form.Label>Apellido</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese apellido"
                  value={apellido}
                  onChange={(event) => setApellido(event.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Ingrese email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  required
                  isInvalid={!emailValido || emailEnUso}
                />
                {!emailValido && (
                  <Form.Control.Feedback type="invalid">
                    Email no válido.
                  </Form.Control.Feedback>
                )}
                {emailEnUso && (
                  <Form.Control.Feedback type="invalid">
                    Este email ya está en uso.
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formEstado">
                <Form.Label>Estado</Form.Label>
                <Form.Select
                  value={estado ? "activo" : "bloqueado"}
                  onChange={(event) =>
                    setEstado(event.target.value === "activo")
                  }
                  required
                >
                  <option value="bloqueado">Activo</option>
                  <option value="activo">Bloqueado</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="5">
              <Form.Group controlId="formClave">
                <Form.Label>Contraseña</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese contraseña"
                  value={clave}
                  onChange={(event) => setClave(event.target.value)}
                  required
                  isInvalid={!claveValida}
                />
                {!claveValida && (
                  <Form.Control.Feedback type="invalid">
                    Contraseña no válida.
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md="7">
              {clave && (
                <div className="card border-info shadow">
                  <ul className="list-unstyled">
                    <li
                      className={`password-validation-item ${
                        passwordValidation.length
                          ? "text-success"
                          : "text-danger"
                      }`}
                    >
                      {passwordValidation.length ? (
                        <i className="bi bi-check"></i>
                      ) : (
                        <i className="bi bi-x"></i>
                      )}
                      Al menos 8 caracteres de largo
                    </li>
                    <li
                      className={`password-validation-item ${
                        passwordValidation.lowercase
                          ? "text-success"
                          : "text-danger"
                      }`}
                    >
                      {passwordValidation.lowercase ? (
                        <i className="bi bi-check"></i>
                      ) : (
                        <i className="bi bi-x"></i>
                      )}
                      Letras minúsculas (a-z)
                    </li>
                    <li
                      className={`password-validation-item ${
                        passwordValidation.uppercase
                          ? "text-success"
                          : "text-danger"
                      }`}
                    >
                      {passwordValidation.uppercase ? (
                        <i className="bi bi-check"></i>
                      ) : (
                        <i className="bi bi-x"></i>
                      )}
                      Letras mayúsculas (A-Z)
                    </li>
                    <li
                      className={`password-validation-item ${
                        passwordValidation.number
                          ? "text-success"
                          : "text-danger"
                      }`}
                    >
                      {passwordValidation.number ? (
                        <i className="bi bi-check"></i>
                      ) : (
                        <i className="bi bi-x"></i>
                      )}
                      Números (0-9)
                    </li>
                    <li
                      className={`password-validation-item ${
                        passwordValidation.specialChar
                          ? "text-success"
                          : "text-danger"
                      }`}
                    >
                      {passwordValidation.specialChar ? (
                        <i className="bi bi-check"></i>
                      ) : (
                        <i className="bi bi-x"></i>
                      )}
                      Caracteres especiales <br /> (por ejemplo: -_@$!%*?&)
                    </li>
                  </ul>
                </div>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelar}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Guardar Cambios
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default EditEmpleadoModal
