import React, { useState } from "react"
import { Button, Card, Container, Row, Col, Modal } from "react-bootstrap"
import { Link } from "react-router-dom"
import { TableProps } from "../../interfaces/CamposTablaGenerica"
import { IEventos } from "../../interfaces/IEventos"
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import "dayjs/locale/es"
import "./EventosPage.css"
import { formatDateGuiones, hasEventPassed } from "../../utils/dateUtils"

dayjs.locale("es")

function EventosPage({
  data,
  onAdd,
  onUpdate,
  onDelete,
}: TableProps<IEventos>) {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null)
  const [selectedYear, setSelectedYear] = useState<number | null>(null)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [itemToDelete, setItemToDelete] = useState<IEventos | null>(null)

  const filteredData = data.filter((item) => {
    const date = new Date(item.fecha)
    const day = date.getDate() + 1
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    return (
      (selectedYear ? year === selectedYear : true) &&
      (selectedMonth ? month === selectedMonth : true) &&
      (!selectedDate ||
        (day === selectedDate.getDate() &&
          month === selectedDate.getMonth() + 1 &&
          year === selectedDate.getFullYear()))
    )
  })

  const handleDeleteConfirm = () => {
    if (itemToDelete && onDelete) {
      onDelete(itemToDelete)
      setItemToDelete(null)
      setShowModal(false)
    }
  }

  const handleShowModal = (item: IEventos) => {
    setItemToDelete(item)
    setShowModal(true)
  }

  const clearFilters = () => {
    setSelectedDate(null)
    setSelectedMonth(null)
    setSelectedYear(null)
  }

  return (
    <Container fluid>
      <Row className="align-items-center mb-3">
        <Col xs={12} sm={10} className="text-start mb-3">
          <Button
            variant="primary"
            onClick={onAdd}
            className="me-2 btn-agregar-eventos"
          >
            Agregar Evento <i className="bi bi-plus-square"></i>
          </Button>
        </Col>

        <Col xs={12} sm={8} className="text-end">
          <div className="d-flex flex-column flex-sm-row align-items-center justify-content-end">
            <Button
              variant="secondary"
              className="ms-2 btn-limpiar mb-1 w-sm-auto"
              onClick={clearFilters}
            >
              Limpiar
            </Button>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Seleccionar Fecha"
                value={selectedDate ? dayjs(selectedDate) : null}
                onChange={(newValue) =>
                  setSelectedDate(newValue ? newValue.toDate() : null)
                }
                className="ms-4 w-100 w-sm-auto date-picker"
                format="DD/MM/YYYY"
              />
              <DatePicker
                label="Seleccionar Mes"
                value={selectedMonth ? dayjs().month(selectedMonth - 1) : null}
                onChange={(newValue) => {
                  if (newValue) {
                    setSelectedMonth(newValue.month() + 1)
                  } else {
                    setSelectedMonth(null)
                  }
                }}
                views={["month"]}
                openTo="month"
                className="ms-4 w-100 w-sm-auto"
              />
              <DatePicker
                label="Seleccionar Año"
                value={selectedYear ? dayjs(`${selectedYear}-01-01`) : null}
                onChange={(newValue) => {
                  if (newValue) {
                    setSelectedYear(newValue.year())
                  } else {
                    setSelectedYear(null)
                  }
                }}
                views={["year"]}
                openTo="year"
                className="ms-4 w-100 w-sm-auto"
              />
            </LocalizationProvider>
          </div>
        </Col>
      </Row>
      <Row>
        {filteredData.map((item, index) => (
          <Col xs={12} sm={6} md={4} lg={3} key={index} className="mb-3">
            <Card
              className={
                !hasEventPassed(item.fecha)
                  ? "card-eventos"
                  : "card-eventos-passed"
              }
            >
              <Link to={`/egresados/${item.id}`} className="link-card">
                <Card.Body className="card-body-eventos">
                  <Card.Title
                    className={
                      !hasEventPassed(item.fecha)
                        ? "card-title"
                        : "card-title-passed"
                    }
                  >
                    {item.nombre}
                  </Card.Title>
                  <Card.Text
                    className={
                      !hasEventPassed(item.fecha)
                        ? "card-title"
                        : "card-title-passed"
                    }
                  >
                    {formatDateGuiones(item.fecha)}
                  </Card.Text>
                </Card.Body>
              </Link>
              <div
                className="d-flex justify-content-center"
                style={{ gap: "18%" }}
              >
                {onUpdate && (
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      e.stopPropagation()
                      onUpdate(item)
                    }}
                  >
                    <i className="bi bi-pencil-square"></i>
                  </Button>
                )}
                {onDelete && (
                  <Button
                    variant="danger"
                    onClick={(e) => {
                      e.stopPropagation()
                      handleShowModal(item)
                    }}
                  >
                    <i className="bi bi-trash"></i>
                  </Button>
                )}
              </div>
            </Card>
          </Col>
        ))}
      </Row>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Eliminación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¿Estás seguro de que deseas eliminar este evento?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

export default EventosPage
