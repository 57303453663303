import React, { useState, useRef, useEffect } from "react"
import { QRCodeSVG } from "qrcode.react"
import { scanQRCode } from "../Util/scanQRCode"
import QRMessage from "./QRMessage"
import { useAuth0 } from "@auth0/auth0-react"
import "./EmpleadoPage.css"
import Spinner from "../Spinner/Spinner"
import SelectorEventosModal from "./SelectorEventosModal/SelectorEventosModal"
import ConteoIngresos from "./ConteoIngresos/ConteoIngresos"

const EmpleadoPage = () => {
  const [qrData, setQrData] = useState("")
  const [cameraStarted, setCameraStarted] = useState(false)
  const [showNoCameraAccessMessage, setShowNoCameraAccessMessage] =
    useState(false)
  const [camerasAvailable, setCamerasAvailable] = useState(false)
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [showMessage, setShowMessage] = useState(false)
  const [messageText, setMessageText] = useState("")
  const [tarjetasPagadas, setTarjetasPagadas] = useState("")
  const [tarjetasPorIngresar, setTarjetasPorIngresar] = useState("")
  const [fechaEvento, setFechaEvento] = useState("")
  const [messageVariant, setMessageVariant] = useState("danger")
  const [autoRestartCamera, setAutoRestartCamera] = useState(false)
  const API_URL = process.env.REACT_APP_API_SERVER_URL_EGRE_VERIF || ""
  const API_URL_MENOR =
    process.env.REACT_APP_API_SERVER_URL_EGRE_VERIF_MENORES || ""
  const { getAccessTokenSilently } = useAuth0()
  const [loading, setLoading] = useState(false)
  const [showEventModal, setShowEventModal] = useState(true)
  const [showConteoModal, setShowConteoModal] = useState(false)
  const [eventId, setEventId] = useState<number | null>(null)

  useEffect(() => {
    const cameraStateFromLocalStorage = localStorage.getItem("cameraStarted")
    if (cameraStateFromLocalStorage === "true") {
      setCameraStarted(true)
      setupCamera()
    }
  }, [])

  useEffect(() => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const cameras = devices.filter((device) => device.kind === "videoinput")
        setCamerasAvailable(cameras.length > 0)
      })
      .catch((error) => {
        console.error("Error al enumerar dispositivos:", error)
        setCamerasAvailable(false)
      })
  }, [])

  const toggleCamera = () => {
    if (cameraStarted) {
      if (videoRef.current && "srcObject" in videoRef.current) {
        const stream = videoRef.current.srcObject as MediaStream
        if (stream) {
          const tracks = stream.getTracks()
          tracks.forEach((track) => track.stop())
        }
      }
      setCameraStarted(false)
      localStorage.setItem("cameraStarted", "false")
    } else {
      setupCamera()
    }
  }

  const setupCamera = async () => {
    requestCameraPermission()
    setCameraStarted(true)
  }

  useEffect(() => {
    if (cameraStarted) {
      requestAnimationFrame(handleScan)
    }
  }, [cameraStarted])

  useEffect(() => {
    return () => {
      if (cameraStarted && videoRef.current) {
        if ("srcObject" in videoRef.current) {
          const stream = videoRef.current.srcObject as MediaStream
          const tracks = stream.getTracks()
          tracks.forEach((track) => track.stop())
        }
        setCameraStarted(false)
        localStorage.setItem("cameraStarted", "false")
      }
    }
  }, [])

  const closeModal = () => {
    if (cameraStarted) {
      toggleCamera()
    }
    setQrData("")
    setShowMessage(false)
  }

  useEffect(() => {
    if (autoRestartCamera) {
      setupCamera()
      setAutoRestartCamera(false)
    }
  }, [autoRestartCamera])

  const requestCameraPermission = async () => {
    try {
      const constraints = {
        video: {
          facingMode: "environment",
        },
      }

      const stream = await navigator.mediaDevices.getUserMedia(constraints)

      if (videoRef.current) {
        videoRef.current.srcObject = stream

        videoRef.current.addEventListener("loadedmetadata", () => {
          if (videoRef.current) {
            const width = videoRef.current.videoWidth
            const height = videoRef.current.videoHeight
            if (width > 0 && height > 0) {
              videoRef.current.play().then(() => {
                localStorage.setItem("cameraStarted", "true")
              })
            } else {
              console.error("Las dimensiones del video no son válidas.")
              setShowNoCameraAccessMessage(true)
            }
          }
        })
      }
    } catch (error) {
      console.error("Error al acceder a la cámara:", error)
      setShowNoCameraAccessMessage(true)
    }
  }

  const handleScan = async () => {
    if (!videoRef.current) {
      console.error("El elemento de video no está disponible.")
      return
    }

    const videoElement = videoRef.current

    await new Promise<void>((resolve) => {
      if (videoElement.videoWidth && videoElement.videoHeight) {
        resolve()
      } else {
        videoElement.onloadedmetadata = () => {
          resolve()
          videoElement.onloadedmetadata = null
        }
      }
    })

    scanQRCode(videoElement, (qrData: string) => {
      const scannedData = qrData.split("-")
      const dni = scannedData[0]
      const clave = scannedData[1]
      const esQRmenor = scannedData[2]
      verificarIngreso(dni, clave, esQRmenor)
    })
  }

  const verificarIngreso = async (
    dni: string,
    clave: string,
    esQRmenor: string
  ) => {
    setLoading(true)
    try {
      const token = await getAccessTokenSilently()
      let response = new Response()
      if (esQRmenor === "V") {
        response = await fetch(API_URL_MENOR, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ dni, clave }),
        })
      } else {
        response = await fetch(API_URL, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ dni, clave }),
        })
      }

      if (response.status === 200) {
        const responseData = await response.json()
        setMessageText(
          `${responseData.nombre} ${responseData.apellido}\nDNI: ${responseData.dni}\nTarjetas Restantes: \n`
        )
        setTarjetasPagadas(`${responseData.tarjetasPagadas}`)
        setTarjetasPorIngresar(`${responseData.tarjetasPorIngresar}`)
        setFechaEvento(`${responseData.fechaEvento}`)
        setMessageVariant("success")
      } else if (response.status === 418) {
        setMessageText(
          "Ingreso Denegado, ya se consumieron todas las tarjetas de ingreso."
        )
        setMessageVariant("danger")
      } else {
        setMessageText("Ingreso Denegado.")
        setMessageVariant("danger")
      }
      setShowMessage(true)
    } catch (error) {
      console.error("Error al verificar el ingreso:", error)
    } finally {
      setLoading(false)
    }
  }
  const handleNewAction = () => {
    setShowEventModal(true) // Abre el modal al hacer clic en el botón
  }

  const handleCloseModal = () => {
    setShowEventModal(false) // Cierra el modal
  }

  const handleSelectEvent = (selectedEventId: number) => {
    setEventId(selectedEventId) // Guarda el eventId seleccionado
    handleCloseModal() // Cierra el modal
  }

  const handleShowConteoModal = () => {
    setShowConteoModal(true)
  }

  const handleCloseConteoModal = () => {
    setShowConteoModal(false)
  }

  if (loading) return <Spinner />

  return (
    <div className="container mt-5">
      <div className="d-flex flex-column align-items-center">
        <h1
          className="display-4 text-center mb-4"
          style={{ marginTop: "40px" }}
        >
          Escanea el código QR
        </h1>
        {showNoCameraAccessMessage && (
          <div className="alert alert-danger">
            No se ha concedido acceso a la cámara. Por favor, otorgue acceso y
            recargue la página.
          </div>
        )}
        {!camerasAvailable && (
          <div className="alert alert-danger">
            No se detectaron cámaras disponibles. Asegúrese de que haya una
            cámara conectada y recargue la página.
          </div>
        )}
        {cameraStarted ? (
          <div className="embed-responsive embed-responsive-4by3">
            <video
              ref={videoRef}
              className="embed-responsive-item"
              width={"500px"}
            ></video>
          </div>
        ) : (
          <p className="text-info fs-4 text-center">
            Inicie la cámara para empezar
          </p>
        )}
        {qrData && (
          <div className="text-center mt-4">
            <QRCodeSVG value={qrData} size={256} />
          </div>
        )}
        {showMessage && (
          <QRMessage
            message={messageText}
            variant={messageVariant}
            tarjetasPagadas={tarjetasPagadas}
            tarjetasPorIngresar={tarjetasPorIngresar}
            onClose={closeModal}
          />
        )}
      </div>
      {/* Footer para móvil */}
      <div className="footer d-none d-sm-block">
        <button
          className="btn btn-secondary footer-button me-3"
          onClick={handleNewAction}
        >
          Evento
        </button>
        <button
          className="btn btn-primary footer-button"
          onClick={toggleCamera}
          disabled={!camerasAvailable}
        >
          <i className="bi bi-qr-code-scan"></i>{" "}
        </button>
        <button
          className="btn btn-secondary footer-button ms-3"
          onClick={handleShowConteoModal}
        >
          Conteo
        </button>
      </div>
      <div className="footer-mobile d-block d-sm-none">
        <button
          className="btn btn-secondary footer-button me-3"
          onClick={handleNewAction}
        >
          Evento
        </button>
        <button
          className="btn btn-primary footer-button"
          onClick={toggleCamera}
          disabled={!camerasAvailable}
        >
          <i className="bi bi-qr-code-scan"></i>{" "}
        </button>
        <button
          className="btn btn-secondary footer-button ms-3"
          onClick={handleShowConteoModal}
        >
          Conteo
        </button>
      </div>
      {/* Modal para seleccionar eventos */}
      <SelectorEventosModal
        show={showEventModal}
        handleClose={handleCloseModal}
        handleSelectEvent={handleSelectEvent}
      />
      {/* Modal de Conteo de Ingresos */}
      <ConteoIngresos
        show={showConteoModal}
        handleClose={handleCloseConteoModal}
        idEvento={eventId}
      />
    </div>
  )
}

export default EmpleadoPage
