import dayjs from "dayjs"
import { IEventos } from "../interfaces/IEventos"
import { capitalizeFirstLetter } from "./StringUtils"

export const formatDateGuiones = (dateString: string) => {
  return dayjs(dateString).format("DD-MM-YYYY")
}

export const formatDateWithDay = (dateString: string): string => {
  return capitalizeFirstLetter(dayjs(dateString).format("dddd DD-MM-YYYY"))
}

export const sortEventosByDate = (data: IEventos[]): IEventos[] => {
  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.fecha)
    const dateB = new Date(b.fecha)

    // Verificamos si A y B han pasado
    const hasAEventPassed = hasEventPassed(a.fecha)
    const hasBEventPassed = hasEventPassed(b.fecha)

    // Si ambos son futuros o presentes, ordenar de más cercano a más lejano
    if (!hasAEventPassed && !hasBEventPassed) {
      return dateA.getTime() - dateB.getTime()
    }

    // Si ambos son pasados, ordenar de más reciente a más antiguo
    if (hasAEventPassed && hasBEventPassed) {
      return dateB.getTime() - dateA.getTime()
    }

    // Si A es futuro/presente y B es pasado
    if (!hasAEventPassed && hasBEventPassed) return -1
    // Si B es futuro/presente y A es pasado
    if (hasAEventPassed && !hasBEventPassed) return 1

    return 0 // En caso de que sean iguales
  })

  return sortedData // Retorna los datos ordenados
}

export const hasEventPassed = (currentDateString: string): boolean => {
  const eventEndDate = new Date(currentDateString)
  eventEndDate.setHours(60, 0, 0, 0)
  return new Date() > eventEndDate
}
