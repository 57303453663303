/**
 * Realiza una solicitud HTTP utilizando el método especificado con el token JWT.
 * @param method Método HTTP (GET, POST, PUT, DELETE, etc.).
 * @param endpoint URL del endpoint al que se realizará la solicitud.
 * @param body Cuerpo de la solicitud (opcional, utilizado en POST y PUT).
 * @param token Token JWT.
 * @returns Objeto JSON con la respuesta de la solicitud.
 */
export const handleRequest = async (
  method: string,
  endpoint: string,
  body?: object | null,
  token?: string
) => {
  try {
    const headers: { [key: string]: string } = {
      "Content-Type": "application/json",
    }

    if (token) {
      headers["Authorization"] = `Bearer ${token}`
    }

    const response = await fetch(endpoint, {
      method: method,
      headers: headers,
      body: JSON.stringify(body),
    })

    if (response.status === 204) {
      // Si la respuesta es un "No Content" (204), considera que la eliminación fue exitosa.
      return null
    }

    if (response.ok) {
      const data = await response.json()
      return data
    } else {
      throw new Error("Error al realizar la solicitud")
    }
  } catch (error) {
    console.error(error)
  }
}

/**
 * Realiza una solicitud HTTP a un endpoint específico, opcionalmente con un ID y el token JWT.
 * @param method Método HTTP (GET, POST, PUT, DELETE, etc.).
 * @param endpoint URL del endpoint base al que se realizará la solicitud.
 * @param token Token JWT.
 * @param id ID opcional para identificar un recurso específico.
 * @param body Cuerpo de la solicitud (opcional, utilizado en POST y PUT).
 * @returns Objeto JSON con la respuesta de la solicitud.
 */
export const handleRequestSingle = async (
  method: string,
  endpoint: string,
  token: string,
  id?: string,
  body?: object
) => {
  try {
    let url = endpoint
    if (id) {
      url += `/${id}`
    }

    const headers: { [key: string]: string } = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }

    const response = await fetch(url, {
      method: method,
      headers: headers,
      body: JSON.stringify(body),
    })

    if (response.ok) {
      const data = await response.json()
      return data
    } else {
      throw new Error("Error al realizar la solicitud")
    }
  } catch (error) {
    console.error(error)
  }
}
