import React, { useEffect, useState, FormEvent, ChangeEvent } from "react"
import {
  Button,
  Table,
  InputGroup,
  FormControl,
  Container,
  Row,
  Col,
} from "react-bootstrap"
import { TableProps } from "../../interfaces/CamposTablaGenerica"
import { Alert, Snackbar } from "@mui/material"

function GenericTable<T>({
  data,
  columns,
  actions,
  onAdd,
  onImport,
  onExport,
  onUpdate,
  onDelete,
  onView,
  onCopy,
  customSearch,
  isEmployee,
  tieneEvento,
  snackbarOpen,
  snackbarMessage,
}: TableProps<T>) {
  const [searchText, setSearchText] = useState("")
  const [filteredData, setFilteredData] = useState<T[]>(data)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let isMounted = true

    const handleSearch = async () => {
      if (customSearch) {
        setIsLoading(true)
        const filteredData = await customSearch(searchText)
        if (isMounted) {
          setFilteredData(filteredData)
          setIsLoading(false)
        }
      } else {
        setFilteredData(data.filter((item) => defaultSearch(item, searchText)))
      }
    }

    handleSearch()

    return () => {
      isMounted = false
    }
  }, [searchText, data, customSearch])

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }

  const handleSearchSubmit = async (e: FormEvent) => {
    e.preventDefault()
    if (customSearch) {
      setIsLoading(true)
      const filteredData = await customSearch(searchText)
      setFilteredData(filteredData)
      setIsLoading(false)
    }
  }

  const defaultSearch = (item: T, search: string): boolean =>
    columns.some((column) => {
      const value = item[column.field]
      return (
        typeof value === "string" &&
        value.toLowerCase().includes(search.toLowerCase())
      )
    })

  return (
    <Container fluid>
      <Row className="align-items-center">
        <div className="d-flex align-items-center">
          {(tieneEvento || isEmployee) && (
            <div className="me-3">
              {" "}
              {actions.create && (
                <Button variant="primary" onClick={onAdd}>
                  <i className="bi bi-plus-square"></i>
                </Button>
              )}
            </div>
          )}
          {tieneEvento && (
            <>
              <div className="me-3">
                {" "}
                {actions.create && (
                  <Button variant="primary" onClick={onImport}>
                    Importar desde Excel
                  </Button>
                )}
              </div>
              <div>
                {actions.export && (
                  <Button variant="success" onClick={onExport}>
                    Exportar a Excel
                  </Button>
                )}
              </div>
            </>
          )}
        </div>

        <Col xs={12} sm={12} className="mt-2">
          <form onSubmit={handleSearchSubmit} className="d-flex">
            <InputGroup>
              <FormControl
                placeholder={
                  isEmployee
                    ? "Buscar por Nombre o Apellido"
                    : "Buscar por Nombre, Apellido o DNI"
                }
                aria-label="Search"
                aria-describedby="basic-addon2"
                onChange={handleSearchChange}
                value={searchText}
              />
              <Button
                variant="outline-secondary"
                type="submit"
                disabled={isLoading}
              >
                <i className="bi bi-search"></i>
              </Button>
            </InputGroup>
          </form>
        </Col>
      </Row>
      <div className="table-responsive mt-3">
        <Table striped bordered>
          <thead>
            <tr>
              <th>Nº</th>
              {columns.map((column, index) => (
                <th
                  key={index}
                  style={{
                    width: `${column.width ? column.width * 80 : ""}%`,
                  }}
                >
                  {column.title}
                </th>
              ))}
              {(actions.update || actions.delete || actions.view) && (
                <th>Acciones</th>
              )}
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                {columns.map((column, key) => (
                  <td key={key}>
                    {column.render
                      ? column.render(item)
                      : String(item[column.field])}
                  </td>
                ))}
                <td>
                  {actions.copy && (
                    <Button
                      variant="info"
                      className="mx-2"
                      onClick={() => onCopy!(item)}
                    >
                      <i className="bi bi-clipboard"></i>
                    </Button>
                  )}
                  {
                    <Snackbar
                      open={snackbarOpen} // Usar el booleano para abrir el Snackbar
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      autoHideDuration={3000}
                    >
                      <Alert severity="success">{snackbarMessage}</Alert>
                    </Snackbar>
                  }
                  {actions.update && (
                    <Button
                      variant="primary"
                      className="mx-2"
                      onClick={() => onUpdate!(item)}
                    >
                      <i className="bi bi-pencil-square"></i>
                    </Button>
                  )}
                  {actions.delete && (
                    <Button
                      variant="danger"
                      className="mx-2"
                      onClick={() => onDelete!(item)}
                    >
                      <i className="bi bi-trash"></i>
                    </Button>
                  )}
                  {actions.view && (
                    <Button
                      variant="info"
                      className="mx-2"
                      onClick={() => onView!(item)}
                    >
                      <i className="bi bi-eye"></i>
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Container>
  )
}

export default GenericTable
