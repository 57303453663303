import React, { useState, useEffect } from "react"
import AdminPage from "../components/AdminPage/AdminPage"
import { useAuth0 } from "@auth0/auth0-react"
import { useNavigate } from "react-router-dom"

const Admin = () => {
  const { isAuthenticated, user, isLoading } = useAuth0()
  const [isAuthorized, setIsAuthorized] = useState(false)
  const AudienceRol = process.env.REACT_APP_AUDIENCE_ROL || ""
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated && user) {
        const roles = user[AudienceRol]
        if (roles && roles.includes("Admin")) {
          setIsAuthorized(true)
        } else {
          setIsAuthorized(false)
        }
      } else {
        setIsAuthorized(false)
      }
    }
  }, [isAuthenticated, isLoading, user, AudienceRol])

  if (isLoading) {
    return <div>Cargando...</div> // Muestra un mensaje de carga mientras se verifica la autenticación
  }

  if (!isAuthenticated || !isAuthorized) {
    navigate("") // Redirige al usuario a la página de inicio de sesión si no está autenticado
    return null // No renderiza nada mientras redirige
  }

  return (
    <div
      className="d-flex"
      style={{
        width: "100%",
        height: "100vh", // Altura del 100% de la ventana visible
      }}
    >
      <div className="flex-grow-1">
        {/* Contenido principal de la página de administrador */}
        <AdminPage />
      </div>
    </div>
  )
}

export default Admin
