import { useState, useEffect } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react"
import { IEventos } from "../../../interfaces/IEventos"
import { CircularProgress } from "@mui/material"
import {
  formatDateWithDay,
  hasEventPassed,
  sortEventosByDate,
} from "../../../utils/dateUtils"

interface EventSelectionModalProps {
  show: boolean
  handleClose: () => void
  handleSelectEvent: (eventId: number) => void
  selectedEventId?: number
}

const SelectorEventosModal: React.FC<EventSelectionModalProps> = ({
  show,
  handleClose,
  handleSelectEvent,
  selectedEventId,
}) => {
  const [eventos, setEventos] = useState<IEventos[]>([])
  const [selectedEventoId, setSelectedEventoId] = useState<number | undefined>(
    selectedEventId
  )
  const [loading, setLoading] = useState(false)
  const { getAccessTokenSilently } = useAuth0()
  const API_URL_EVENTOS = process.env.REACT_APP_API_SERVER_URL_EVENTOS || ""

  useEffect(() => {
    const fetchEventos = async () => {
      setLoading(true)
      try {
        const token = await getAccessTokenSilently()
        const response = await axios.get(API_URL_EVENTOS, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        setEventos(sortEventosByDate(response.data))
      } catch (error) {
        console.error("Error al obtener eventos:", error)
      } finally {
        setLoading(false)
      }
    }
    fetchEventos()
  }, [getAccessTokenSilently, API_URL_EVENTOS])

  const handleAccept = () => {
    if (selectedEventoId) {
      handleSelectEvent(selectedEventoId)
      handleClose()
    }
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="modal-dialog-centered"
    >
      <Modal.Header closeButton>
        <Modal.Title>Seleccionar Evento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <CircularProgress />}
        <Form.Group controlId="formEvento">
          <Form.Label>Evento</Form.Label>
          <Form.Control
            as="select"
            value={selectedEventoId}
            onChange={(e) => {
              setSelectedEventoId(Number(e.target.value))
            }}
            required
          >
            <option value="">Seleccione un evento</option>
            {eventos.map((evento) => {
              const isEventPassed = hasEventPassed(evento.fecha)
              return (
                <option
                  key={evento.id}
                  value={evento.id}
                  style={{ color: isEventPassed ? "#a6a8aa" : "black" }}
                >
                  {`${evento.nombre} / ${formatDateWithDay(evento.fecha)}`}
                </option>
              )
            })}
          </Form.Control>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleAccept}>
          Aceptar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SelectorEventosModal
