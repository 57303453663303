import React, { FC, useState, useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import LoginButton from "../Auth0/LoginButton"
import LogoutButton from "../Auth0/LogoutButton"
import { Nav, Navbar, NavDropdown } from "react-bootstrap"
import { Link } from "react-router-dom"
import "./NavBar.css"

const NavBar: FC = () => {
  const { isAuthenticated, user } = useAuth0()
  const AudienceRol = process.env.REACT_APP_AUDIENCE_ROL || ""
  const [showUserDropdown, setShowUserDropdown] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)

  const closeDropdown = () => {
    setShowUserDropdown(false)
  }

  useEffect(() => {
    if (showUserDropdown) {
      document.addEventListener("click", closeDropdown)
    } else {
      document.removeEventListener("click", closeDropdown)
    }

    return () => {
      document.removeEventListener("click", closeDropdown)
    }
  }, [showUserDropdown])

  const roles = user ? user[AudienceRol] : []
  const userHasRoles = roles && roles.length > 0

  return (
    <Navbar bg="dark" variant="dark" fixed="top" style={{ height: "5rem" }}>
      <div className="container">
        <div
          className="navbar-brand"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Link to="/">
            <img
              className="img-fluid rounded-circle me-2"
              src={`${process.env.PUBLIC_URL}/assets/img/DaVinciLogo.jpeg`}
              alt="Logo de Da Vinci Festa"
              style={{ width: "60px", height: "60px" }}
            />
          </Link>

          {!isMobile && (
            <Link to="/" className="text-decoration-none text-white">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  fontSize: "0.8em",
                }}
              >
                Da Vinci Festa
                <div style={{ fontSize: "0.8em" }}>Creadores de alegrías</div>
              </div>
            </Link>
          )}
        </div>

        {!isMobile && (
          <Link
            to="/#sobre-nosotros"
            className="text-decoration-none text-white nav-item"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                fontSize: "0.8em",
              }}
            >
              Sobre nosotros
            </div>
          </Link>
        )}

        <Link
          to="/#generador-qr"
          className={
            isAuthenticated
              ? "text-decoration-none text-white"
              : "text-decoration-none text-white pulse-item"
          }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              fontSize: "0.8em",
              marginRight: "4px",
              width: `${isMobile ? "3.5rem" : ""}`,
            }}
          >
            Descargar QRs
          </div>
          {!isAuthenticated && <i className="bi bi-arrow-down"></i>}
        </Link>
        {!isMobile && (
          <Link
            to="/#donde-estamos"
            className="text-decoration-none text-white nav-item"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                fontSize: "0.8em",
              }}
            >
              Donde estamos
            </div>
          </Link>
        )}
        <Nav className="ml-auto">
          {isAuthenticated && userHasRoles && (
            <div className="d-flex align-items-center text-white">
              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  width: "78px",
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  setShowUserDropdown(!showUserDropdown)
                }}
              >
                <img
                  src={user?.picture}
                  alt="imagen usuario"
                  className="img-fluid rounded-circle me-0"
                  style={{ width: "50px", height: "50px" }}
                />
                <NavDropdown
                  show={showUserDropdown}
                  title=""
                  className="me-3"
                  align="end"
                >
                  {roles.includes("Admin") && (
                    <>
                      <Link to="/admin" className="dropdown-item">
                        Administrar
                      </Link>
                      <div className="dropdown-divider"></div>
                      <Link to="/empleado" className="dropdown-item">
                        Escanear QR's
                        <i
                          className="bi bi-qr-code-scan"
                          style={{ fontSize: "1rem", marginLeft: "5px" }}
                        ></i>
                      </Link>
                    </>
                  )}
                  {roles.includes("Empleado") && (
                    <Link to="/empleado" className="dropdown-item">
                      Escanear QR's
                      <i
                        className="bi bi-qr-code-scan"
                        style={{ fontSize: "1rem", marginLeft: "5px" }}
                      ></i>
                    </Link>
                  )}
                </NavDropdown>
              </div>
            </div>
          )}
          {isAuthenticated ? <LogoutButton /> : <LoginButton />}
        </Nav>
      </div>
    </Navbar>
  )
}

export default NavBar
