import React from "react"
import { Box, Container, Typography } from "@mui/material"

const DondeEstamos = () => {
  return (
    <Box
      sx={{
        height: "auto",
        backgroundImage: `url('/assets/img/DondeEstamos.jpg')`,
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 3,
      }}
    >
      <Typography
        variant="h4"
        component="h2"
        color="white"
        textAlign="center"
        sx={{ mt: 9 }}
      >
        ¿Dónde Estamos?
      </Typography>
      <Container sx={{ maxWidth: "90%", mt: 3, mb: 5 }}>
        <Box
          component="iframe"
          title="Mapa de ubicación"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11240.071718135472!2d-68.6526104!3d-33.0970632!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x967e5d59a4f54c3f%3A0x8a86a6a5132396eb!2sDa%20Vinci%20Festa!5e0!3m2!1sen!2sar!4v1624268234309!5m2!1sen!2sar"
          sx={{
            width: "100%",
            border: "0",
            height: "25rem",
            maxHeight: "65vh",
          }}
          allowFullScreen
          loading="lazy"
        />
      </Container>
    </Box>
  )
}

export default DondeEstamos
