import React, { useState, useEffect, useRef } from "react"
import { Modal, Button, Form, Row, Col } from "react-bootstrap"
import { IEventos, AddEventoModalProps } from "../../interfaces/IEventos"
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs, { Dayjs } from "dayjs"
import "dayjs/locale/es"
import "./AddEventoModal.css"

const AddEventoModal: React.FC<AddEventoModalProps> = ({
  show,
  handleClose,
  handleEventoAdd,
}) => {
  const [nombre, setNombre] = useState("")
  const [fecha, setFecha] = useState<Dayjs | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const [isFechaValid, setIsFechaValid] = useState(true)
  const [isModalOpened, setIsModalOpened] = useState(false)

  const resetForm = () => {
    setNombre("")
    setFecha(null)
    setIsSubmitting(false)
    setIsFechaValid(true)
    setIsModalOpened(false)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    // Validar la fecha antes de enviar
    if (!isFechaValid || !fecha) {
      setIsFechaValid(false)
      return
    }

    setIsSubmitting(true)

    const nuevoEvento: IEventos = {
      id: Date.now(),
      nombre,
      fecha: fecha.format("YYYY-MM-DD"),
      listaEgresados: [],
    }

    try {
      handleEventoAdd(nuevoEvento)
      handleClose()
      resetForm()
    } catch (error) {
      console.error("Error al agregar evento:", error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleCloseModal = () => {
    resetForm()
    handleClose()
  }

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto"
      textAreaRef.current.style.height = `${Math.max(
        textAreaRef.current.scrollHeight,
        50
      )}px`
    }
  }, [nombre])

  const handleFechaChange = (newValue: Dayjs | null) => {
    if (newValue) {
      setFecha(newValue)
      setIsFechaValid(true) // Actualiza a válido si hay una fecha seleccionada
    } else {
      setFecha(null)
      setIsFechaValid(false) // Marca como inválido si no hay fecha
    }
  }

  useEffect(() => {
    if (show) {
      setIsModalOpened(true)
    } else {
      resetForm()
    }
  }, [show])

  return (
    <Modal show={show} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar Evento</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Col md="6">
              <Form.Group className="mb-3" controlId="formNombre">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Ingrese nombre"
                  value={nombre}
                  onChange={(event) => setNombre(event.target.value)}
                  required
                  ref={textAreaRef}
                  className="form-control-lg custom-placeholder-add"
                  style={{ resize: "none" }}
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="mb-3" controlId="formFecha">
                <Form.Label>Fecha</Form.Label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Seleccionar Fecha"
                    value={fecha ? dayjs(fecha) : null}
                    onChange={handleFechaChange} // Cambiar a la nueva función
                    format="DD/MM/YYYY"
                    className="ms-4 date-picker form-control-lg"
                    onError={(error) => {
                      setIsFechaValid(!error) // Actualiza la validez en base al error
                    }}
                  />
                </LocalizationProvider>
                {!isFechaValid && (
                  <Form.Text className="text-danger">
                    Este campo es obligatorio y debe ser una fecha válida
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Agregar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddEventoModal
